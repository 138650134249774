@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap");
/*$adminTheme: mat.define-dark-theme((
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn),
));*/
/*$adminTheme: mat.define-dark-theme((
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn),
));*/
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, transparent);
  color: var(--mat-app-text-color, inherit);
}

html {
  --mat-badge-text-font: Open Sans;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
  font: 500 32px / 40px Open Sans;
  letter-spacing: 0em;
  margin: 0 0 16px;
}

.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2 {
  font: 500 20px / 32px Open Sans;
  letter-spacing: 0.0075em;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3 {
  font: 400 16px / 20px Open Sans;
  letter-spacing: 0.0094em;
  margin: 0 0 16px;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4 {
  font: 500 14px / 24px Open Sans;
  letter-spacing: 0.0179em;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Open Sans;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Open Sans;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-subtitle-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-subtitle-2 {
  font: 500 15px / 24px Open Sans;
  letter-spacing: 0.0067em;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font: 400 14px / 20px Open Sans;
  letter-spacing: 0.0179em;
}
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Open Sans;
  letter-spacing: 0.0333em;
}

.mat-headline-1,
.mat-typography .mat-headline-1 {
  font: 300 112px / 112px Open Sans;
  letter-spacing: -0.0134em;
  margin: 0 0 56px;
}

.mat-headline-2,
.mat-typography .mat-headline-2 {
  font: 400 56px / 56px Open Sans;
  letter-spacing: -0.0089em;
  margin: 0 0 64px;
}

.mat-headline-3,
.mat-typography .mat-headline-3 {
  font: 400 45px / 48px Open Sans;
  letter-spacing: 0em;
  margin: 0 0 64px;
}

.mat-headline-4,
.mat-typography .mat-headline-4 {
  font: 400 34px / 40px Open Sans;
  letter-spacing: 0.0074em;
  margin: 0 0 64px;
}

html {
  --mat-bottom-sheet-container-text-font: Open Sans;
  --mat-bottom-sheet-container-text-line-height: 20px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: 0.0179em;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-label-text-font: Open Sans;
  --mat-legacy-button-toggle-label-text-line-height: 24px;
  --mat-legacy-button-toggle-label-text-size: 14px;
  --mat-legacy-button-toggle-label-text-tracking: 0.0179em;
  --mat-legacy-button-toggle-label-text-weight: 500;
  --mat-standard-button-toggle-label-text-font: Open Sans;
  --mat-standard-button-toggle-label-text-line-height: 24px;
  --mat-standard-button-toggle-label-text-size: 14px;
  --mat-standard-button-toggle-label-text-tracking: 0.0179em;
  --mat-standard-button-toggle-label-text-weight: 500;
}

html {
  --mat-datepicker-calendar-text-font: Open Sans;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 500;
}

html {
  --mat-expansion-header-text-font: Open Sans;
  --mat-expansion-header-text-size: 15px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Open Sans;
  --mat-expansion-container-text-line-height: 20px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: 0.0179em;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-stepper-container-text-font: Open Sans;
  --mat-stepper-header-label-text-font: Open Sans;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 14px;
  --mat-stepper-header-selected-state-label-text-size: 14px;
  --mat-stepper-header-selected-state-label-text-weight: 500;
}

html {
  --mat-toolbar-title-text-font: Open Sans;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: 0.0075em;
  --mat-toolbar-title-text-weight: 500;
}

html {
  --mat-tree-node-text-font: Open Sans;
  --mat-tree-node-text-size: 14px;
  --mat-tree-node-text-weight: 400;
}

html {
  --mat-option-label-text-font: Open Sans;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 14px;
  --mat-option-label-text-tracking: 0.0179em;
  --mat-option-label-text-weight: 500;
}

html {
  --mat-optgroup-label-text-font: Open Sans;
  --mat-optgroup-label-text-line-height: 24px;
  --mat-optgroup-label-text-size: 14px;
  --mat-optgroup-label-text-tracking: 0.0179em;
  --mat-optgroup-label-text-weight: 500;
}

html {
  --mat-card-title-text-font: Open Sans;
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: 0.0075em;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: Open Sans;
  --mat-card-subtitle-text-line-height: 24px;
  --mat-card-subtitle-text-size: 15px;
  --mat-card-subtitle-text-tracking: 0.0067em;
  --mat-card-subtitle-text-weight: 500;
}

html {
  --mdc-plain-tooltip-supporting-text-font: Open Sans;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333em;
}

html {
  --mdc-filled-text-field-label-text-font: Open Sans;
  --mdc-filled-text-field-label-text-size: 14px;
  --mdc-filled-text-field-label-text-tracking: 0.0179em;
  --mdc-filled-text-field-label-text-weight: 500;
  --mdc-outlined-text-field-label-text-font: Open Sans;
  --mdc-outlined-text-field-label-text-size: 14px;
  --mdc-outlined-text-field-label-text-tracking: 0.0179em;
  --mdc-outlined-text-field-label-text-weight: 500;
  --mat-form-field-container-text-font: Open Sans;
  --mat-form-field-container-text-line-height: 24px;
  --mat-form-field-container-text-size: 14px;
  --mat-form-field-container-text-tracking: 0.0179em;
  --mat-form-field-container-text-weight: 500;
  --mat-form-field-outlined-label-text-populated-size: 14px;
  --mat-form-field-subscript-text-font: Open Sans;
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: 0.0333em;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-trigger-text-font: Open Sans;
  --mat-select-trigger-text-line-height: 24px;
  --mat-select-trigger-text-size: 14px;
  --mat-select-trigger-text-tracking: 0.0179em;
  --mat-select-trigger-text-weight: 500;
}

html {
  --mdc-dialog-subhead-font: Open Sans;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0075em;
  --mdc-dialog-supporting-text-font: Open Sans;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 14px;
  --mdc-dialog-supporting-text-weight: 500;
  --mdc-dialog-supporting-text-tracking: 0.0179em;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Open Sans;
  --mdc-chip-label-text-line-height: 20px;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: 0.0179em;
  --mdc-chip-label-text-weight: 400;
}

html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-font: Open Sans;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0179em;
  --mdc-form-field-label-text-weight: 400;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-font: Open Sans;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0179em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-slider-label-label-text-font: Open Sans;
  --mdc-slider-label-label-text-size: 15px;
  --mdc-slider-label-label-text-line-height: 24px;
  --mdc-slider-label-label-text-tracking: 0.0067em;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-item-label-text-font: Open Sans;
  --mat-menu-item-label-text-size: 14px;
  --mat-menu-item-label-text-tracking: 0.0179em;
  --mat-menu-item-label-text-line-height: 24px;
  --mat-menu-item-label-text-weight: 500;
}

html {
  --mdc-list-list-item-label-text-font: Open Sans;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 14px;
  --mdc-list-list-item-label-text-tracking: 0.0179em;
  --mdc-list-list-item-label-text-weight: 500;
  --mdc-list-list-item-supporting-text-font: Open Sans;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: 0.0179em;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Open Sans;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.0333em;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font: 400 16px / 20px Open Sans;
  letter-spacing: 0.0094em;
}

html {
  --mat-paginator-container-text-font: Open Sans;
  --mat-paginator-container-text-line-height: 20px;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: 0.0333em;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Roboto, sans-serif;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: 0.0892857143em;
  --mat-tab-header-label-text-line-height: 36px;
  --mat-tab-header-label-text-weight: 500;
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-font: Open Sans;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0179em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-text-button-label-text-font: Roboto, sans-serif;
  --mdc-text-button-label-text-size: 14px;
  --mdc-text-button-label-text-tracking: 0.0892857143em;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-label-text-transform: none;
  --mdc-filled-button-label-text-font: Roboto, sans-serif;
  --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-tracking: 0.0892857143em;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-label-text-transform: none;
  --mdc-outlined-button-label-text-font: Roboto, sans-serif;
  --mdc-outlined-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: 0.0892857143em;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-label-text-transform: none;
  --mdc-protected-button-label-text-font: Roboto, sans-serif;
  --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-tracking: 0.0892857143em;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-label-text-transform: none;
}

html {
  --mdc-extended-fab-label-text-font: Roboto, sans-serif;
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: 0.0892857143em;
  --mdc-extended-fab-label-text-weight: 500;
}

html {
  --mdc-snackbar-supporting-text-font: Open Sans;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-header-headline-font: Open Sans;
  --mat-table-header-headline-line-height: 24px;
  --mat-table-header-headline-size: 15px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: 0.0067em;
  --mat-table-row-item-label-text-font: Open Sans;
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: 0.0179em;
  --mat-table-footer-supporting-text-font: Open Sans;
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: 0.0179em;
}

.material-symbols-rounded {
  font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 50, "opsz" 20;
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #232323;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #2ec610;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #a33c30;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #232323;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #232323;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: #2ec610;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #2ec610;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #2ec610;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #2ec610;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #a33c30;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #a33c30;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mat-option-label-text-font: Open Sans;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 14px;
  --mat-option-label-text-tracking: 0.0179em;
  --mat-option-label-text-weight: 500;
}

html {
  --mat-optgroup-label-text-font: Open Sans;
  --mat-optgroup-label-text-line-height: 24px;
  --mat-optgroup-label-text-size: 14px;
  --mat-optgroup-label-text-tracking: 0.0179em;
  --mat-optgroup-label-text-weight: 500;
}

html {
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}

html {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-card-title-text-font: Open Sans;
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: 0.0075em;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: Open Sans;
  --mat-card-subtitle-text-line-height: 24px;
  --mat-card-subtitle-text-size: 15px;
  --mat-card-subtitle-text-tracking: 0.0067em;
  --mat-card-subtitle-text-weight: 500;
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #232323;
  --mdc-linear-progress-track-color: rgba(35, 35, 35, 0.25);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #2ec610;
  --mdc-linear-progress-track-color: rgba(46, 198, 16, 0.25);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #a33c30;
  --mdc-linear-progress-track-color: rgba(163, 60, 48, 0.25);
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-plain-tooltip-supporting-text-font: Open Sans;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333em;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}

html {
  --mdc-filled-text-field-caret-color: #232323;
  --mdc-filled-text-field-focus-active-indicator-color: #232323;
  --mdc-filled-text-field-focus-label-text-color: rgba(35, 35, 35, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #a33c30;
  --mdc-filled-text-field-error-focus-label-text-color: #a33c30;
  --mdc-filled-text-field-error-label-text-color: #a33c30;
  --mdc-filled-text-field-error-caret-color: #a33c30;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #a33c30;
  --mdc-filled-text-field-error-focus-active-indicator-color: #a33c30;
  --mdc-filled-text-field-error-hover-active-indicator-color: #a33c30;
  --mdc-outlined-text-field-caret-color: #232323;
  --mdc-outlined-text-field-focus-outline-color: #232323;
  --mdc-outlined-text-field-focus-label-text-color: rgba(35, 35, 35, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #a33c30;
  --mdc-outlined-text-field-error-focus-label-text-color: #a33c30;
  --mdc-outlined-text-field-error-label-text-color: #a33c30;
  --mdc-outlined-text-field-error-hover-label-text-color: #a33c30;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #a33c30;
  --mdc-outlined-text-field-error-hover-outline-color: #a33c30;
  --mdc-outlined-text-field-error-outline-color: #a33c30;
  --mat-form-field-focus-select-arrow-color: rgba(35, 35, 35, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #a33c30;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #2ec610;
  --mdc-filled-text-field-focus-active-indicator-color: #2ec610;
  --mdc-filled-text-field-focus-label-text-color: rgba(46, 198, 16, 0.87);
  --mdc-outlined-text-field-caret-color: #2ec610;
  --mdc-outlined-text-field-focus-outline-color: #2ec610;
  --mdc-outlined-text-field-focus-label-text-color: rgba(46, 198, 16, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(46, 198, 16, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #a33c30;
  --mdc-filled-text-field-focus-active-indicator-color: #a33c30;
  --mdc-filled-text-field-focus-label-text-color: rgba(163, 60, 48, 0.87);
  --mdc-outlined-text-field-caret-color: #a33c30;
  --mdc-outlined-text-field-focus-outline-color: #a33c30;
  --mdc-outlined-text-field-focus-label-text-color: rgba(163, 60, 48, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(163, 60, 48, 0.87);
}

html {
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
}

html {
  --mdc-filled-text-field-label-text-font: Open Sans;
  --mdc-filled-text-field-label-text-size: 14px;
  --mdc-filled-text-field-label-text-tracking: 0.0179em;
  --mdc-filled-text-field-label-text-weight: 500;
  --mdc-outlined-text-field-label-text-font: Open Sans;
  --mdc-outlined-text-field-label-text-size: 14px;
  --mdc-outlined-text-field-label-text-tracking: 0.0179em;
  --mdc-outlined-text-field-label-text-weight: 500;
  --mat-form-field-container-text-font: Open Sans;
  --mat-form-field-container-text-line-height: 24px;
  --mat-form-field-container-text-size: 14px;
  --mat-form-field-container-text-tracking: 0.0179em;
  --mat-form-field-container-text-weight: 500;
  --mat-form-field-outlined-label-text-populated-size: 14px;
  --mat-form-field-subscript-text-font: Open Sans;
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: 0.0333em;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(35, 35, 35, 0.87);
  --mat-select-invalid-arrow-color: rgba(163, 60, 48, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(46, 198, 16, 0.87);
  --mat-select-invalid-arrow-color: rgba(163, 60, 48, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(163, 60, 48, 0.87);
  --mat-select-invalid-arrow-color: rgba(163, 60, 48, 0.87);
}

html {
  --mat-select-arrow-transform: translateY(-8px);
}

html {
  --mat-select-trigger-text-font: Open Sans;
  --mat-select-trigger-text-line-height: 24px;
  --mat-select-trigger-text-size: 14px;
  --mat-select-trigger-text-tracking: 0.0179em;
  --mat-select-trigger-text-weight: 500;
}

html {
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-autocomplete-background-color: white;
}

html {
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
}

html {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

html {
  --mdc-dialog-subhead-font: Open Sans;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0075em;
  --mdc-dialog-supporting-text-font: Open Sans;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 14px;
  --mdc-dialog-supporting-text-weight: 500;
  --mdc-dialog-supporting-text-tracking: 0.0179em;
}

.mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: #efefef;
  --mdc-chip-elevated-container-color: #232323;
  --mdc-chip-elevated-selected-container-color: #232323;
  --mdc-chip-elevated-disabled-container-color: #232323;
  --mdc-chip-flat-disabled-selected-container-color: #232323;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #efefef;
  --mdc-chip-selected-label-text-color: #efefef;
  --mdc-chip-with-icon-icon-color: #efefef;
  --mdc-chip-with-icon-disabled-icon-color: #efefef;
  --mdc-chip-with-icon-selected-icon-color: #efefef;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #efefef;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #efefef;
  --mat-chip-selected-disabled-trailing-icon-color: #efefef;
  --mat-chip-selected-trailing-icon-color: #efefef;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: rgba(35, 35, 35, 0.87);
  --mdc-chip-elevated-container-color: #2ec610;
  --mdc-chip-elevated-selected-container-color: #2ec610;
  --mdc-chip-elevated-disabled-container-color: #2ec610;
  --mdc-chip-flat-disabled-selected-container-color: #2ec610;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: rgba(35, 35, 35, 0.87);
  --mdc-chip-selected-label-text-color: rgba(35, 35, 35, 0.87);
  --mdc-chip-with-icon-icon-color: rgba(35, 35, 35, 0.87);
  --mdc-chip-with-icon-disabled-icon-color: rgba(35, 35, 35, 0.87);
  --mdc-chip-with-icon-selected-icon-color: rgba(35, 35, 35, 0.87);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: rgba(35, 35, 35, 0.87);
  --mdc-chip-with-trailing-icon-trailing-icon-color: rgba(35, 35, 35, 0.87);
  --mat-chip-selected-disabled-trailing-icon-color: rgba(35, 35, 35, 0.87);
  --mat-chip-selected-trailing-icon-color: rgba(35, 35, 35, 0.87);
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: #efefef;
  --mdc-chip-elevated-container-color: #a33c30;
  --mdc-chip-elevated-selected-container-color: #a33c30;
  --mdc-chip-elevated-disabled-container-color: #a33c30;
  --mdc-chip-flat-disabled-selected-container-color: #a33c30;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #efefef;
  --mdc-chip-selected-label-text-color: #efefef;
  --mdc-chip-with-icon-icon-color: #efefef;
  --mdc-chip-with-icon-disabled-icon-color: #efefef;
  --mdc-chip-with-icon-selected-icon-color: #efefef;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #efefef;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #efefef;
  --mat-chip-selected-disabled-trailing-icon-color: #efefef;
  --mat-chip-selected-trailing-icon-color: #efefef;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Open Sans;
  --mdc-chip-label-text-line-height: 20px;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: 0.0179em;
  --mdc-chip-label-text-weight: 400;
}

html {
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}

html {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
html {
  --mdc-switch-state-layer-size: 40px;
}

html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-font: Open Sans;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0179em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #232323;
  --mdc-radio-selected-hover-icon-color: #232323;
  --mdc-radio-selected-icon-color: #232323;
  --mdc-radio-selected-pressed-icon-color: #232323;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #232323;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #2ec610;
  --mdc-radio-selected-hover-icon-color: #2ec610;
  --mdc-radio-selected-icon-color: #2ec610;
  --mdc-radio-selected-pressed-icon-color: #2ec610;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #2ec610;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #a33c30;
  --mdc-radio-selected-hover-icon-color: #a33c30;
  --mdc-radio-selected-icon-color: #a33c30;
  --mdc-radio-selected-pressed-icon-color: #a33c30;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #a33c30;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-radio-state-layer-size: 40px;
  --mat-radio-touch-target-display: block;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-font: Open Sans;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0179em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
}

html {
  --mdc-slider-handle-color: #232323;
  --mdc-slider-focus-handle-color: #232323;
  --mdc-slider-hover-handle-color: #232323;
  --mdc-slider-active-track-color: #232323;
  --mdc-slider-inactive-track-color: #232323;
  --mdc-slider-with-tick-marks-inactive-container-color: #232323;
  --mdc-slider-with-tick-marks-active-container-color: #efefef;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: #232323;
  --mat-slider-hover-state-layer-color: rgba(35, 35, 35, 0.05);
  --mat-slider-focus-state-layer-color: rgba(35, 35, 35, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
}
html .mat-accent {
  --mat-slider-ripple-color: #2ec610;
  --mat-slider-hover-state-layer-color: rgba(46, 198, 16, 0.05);
  --mat-slider-focus-state-layer-color: rgba(46, 198, 16, 0.2);
  --mdc-slider-handle-color: #2ec610;
  --mdc-slider-focus-handle-color: #2ec610;
  --mdc-slider-hover-handle-color: #2ec610;
  --mdc-slider-active-track-color: #2ec610;
  --mdc-slider-inactive-track-color: #2ec610;
  --mdc-slider-with-tick-marks-inactive-container-color: #2ec610;
  --mdc-slider-with-tick-marks-active-container-color: rgba(35, 35, 35, 0.87);
}
html .mat-warn {
  --mat-slider-ripple-color: #a33c30;
  --mat-slider-hover-state-layer-color: rgba(163, 60, 48, 0.05);
  --mat-slider-focus-state-layer-color: rgba(163, 60, 48, 0.2);
  --mdc-slider-handle-color: #a33c30;
  --mdc-slider-focus-handle-color: #a33c30;
  --mdc-slider-hover-handle-color: #a33c30;
  --mdc-slider-active-track-color: #a33c30;
  --mdc-slider-inactive-track-color: #a33c30;
  --mdc-slider-with-tick-marks-inactive-container-color: #a33c30;
  --mdc-slider-with-tick-marks-active-container-color: #efefef;
}

html {
  --mdc-slider-label-label-text-font: Open Sans;
  --mdc-slider-label-label-text-size: 15px;
  --mdc-slider-label-label-text-line-height: 24px;
  --mdc-slider-label-label-text-tracking: 0.0067em;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-menu-item-label-text-font: Open Sans;
  --mat-menu-item-label-text-size: 14px;
  --mat-menu-item-label-text-tracking: 0.0179em;
  --mat-menu-item-label-text-line-height: 24px;
  --mat-menu-item-label-text-weight: 500;
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 0;
}

html {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #232323;
  --mdc-radio-selected-hover-icon-color: #232323;
  --mdc-radio-selected-icon-color: #232323;
  --mdc-radio-selected-pressed-icon-color: #232323;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #2ec610;
  --mdc-radio-selected-hover-icon-color: #2ec610;
  --mdc-radio-selected-icon-color: #2ec610;
  --mdc-radio-selected-pressed-icon-color: #2ec610;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #a33c30;
  --mdc-radio-selected-hover-icon-color: #a33c30;
  --mdc-radio-selected-icon-color: #a33c30;
  --mdc-radio-selected-pressed-icon-color: #a33c30;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #efefef;
  --mdc-checkbox-selected-focus-icon-color: #232323;
  --mdc-checkbox-selected-hover-icon-color: #232323;
  --mdc-checkbox-selected-icon-color: #232323;
  --mdc-checkbox-selected-pressed-icon-color: #232323;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #232323;
  --mdc-checkbox-selected-hover-state-layer-color: #232323;
  --mdc-checkbox-selected-pressed-state-layer-color: #232323;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #232323;
  --mdc-checkbox-selected-focus-icon-color: #2ec610;
  --mdc-checkbox-selected-hover-icon-color: #2ec610;
  --mdc-checkbox-selected-icon-color: #2ec610;
  --mdc-checkbox-selected-pressed-icon-color: #2ec610;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #2ec610;
  --mdc-checkbox-selected-hover-state-layer-color: #2ec610;
  --mdc-checkbox-selected-pressed-state-layer-color: #2ec610;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #efefef;
  --mdc-checkbox-selected-focus-icon-color: #a33c30;
  --mdc-checkbox-selected-hover-icon-color: #a33c30;
  --mdc-checkbox-selected-icon-color: #a33c30;
  --mdc-checkbox-selected-pressed-icon-color: #a33c30;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #a33c30;
  --mdc-checkbox-selected-hover-state-layer-color: #a33c30;
  --mdc-checkbox-selected-pressed-state-layer-color: #a33c30;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #232323;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #232323;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

html {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 32px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mdc-list-list-item-label-text-font: Open Sans;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 14px;
  --mdc-list-list-item-label-text-tracking: 0.0179em;
  --mdc-list-list-item-label-text-weight: 500;
  --mdc-list-list-item-supporting-text-font: Open Sans;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: 0.0179em;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Open Sans;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.0333em;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font: 400 16px / 20px Open Sans;
  letter-spacing: 0.0094em;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
}

html {
  --mat-paginator-container-text-font: Open Sans;
  --mat-paginator-container-text-line-height: 20px;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: 0.0333em;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}

html {
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #232323;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #232323;
  --mat-tab-header-active-ripple-color: #232323;
  --mat-tab-header-inactive-ripple-color: #232323;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #232323;
  --mat-tab-header-active-hover-label-text-color: #232323;
  --mat-tab-header-active-focus-indicator-color: #232323;
  --mat-tab-header-active-hover-indicator-color: #232323;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #2ec610;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #2ec610;
  --mat-tab-header-active-ripple-color: #2ec610;
  --mat-tab-header-inactive-ripple-color: #2ec610;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #2ec610;
  --mat-tab-header-active-hover-label-text-color: #2ec610;
  --mat-tab-header-active-focus-indicator-color: #2ec610;
  --mat-tab-header-active-hover-indicator-color: #2ec610;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #a33c30;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #a33c30;
  --mat-tab-header-active-ripple-color: #a33c30;
  --mat-tab-header-inactive-ripple-color: #a33c30;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #a33c30;
  --mat-tab-header-active-hover-label-text-color: #a33c30;
  --mat-tab-header-active-focus-indicator-color: #a33c30;
  --mat-tab-header-active-hover-indicator-color: #a33c30;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #232323;
  --mat-tab-header-with-background-foreground-color: #efefef;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #2ec610;
  --mat-tab-header-with-background-foreground-color: rgba(35, 35, 35, 0.87);
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #a33c30;
  --mat-tab-header-with-background-foreground-color: #efefef;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Roboto, sans-serif;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: 0.0892857143em;
  --mat-tab-header-label-text-line-height: 36px;
  --mat-tab-header-label-text-weight: 500;
}

html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #232323;
  --mdc-checkbox-selected-focus-icon-color: #2ec610;
  --mdc-checkbox-selected-hover-icon-color: #2ec610;
  --mdc-checkbox-selected-icon-color: #2ec610;
  --mdc-checkbox-selected-pressed-icon-color: #2ec610;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #2ec610;
  --mdc-checkbox-selected-hover-state-layer-color: #2ec610;
  --mdc-checkbox-selected-pressed-state-layer-color: #2ec610;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #efefef;
  --mdc-checkbox-selected-focus-icon-color: #232323;
  --mdc-checkbox-selected-hover-icon-color: #232323;
  --mdc-checkbox-selected-icon-color: #232323;
  --mdc-checkbox-selected-pressed-icon-color: #232323;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #232323;
  --mdc-checkbox-selected-hover-state-layer-color: #232323;
  --mdc-checkbox-selected-pressed-state-layer-color: #232323;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #efefef;
  --mdc-checkbox-selected-focus-icon-color: #a33c30;
  --mdc-checkbox-selected-hover-icon-color: #a33c30;
  --mdc-checkbox-selected-icon-color: #a33c30;
  --mdc-checkbox-selected-pressed-icon-color: #a33c30;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #a33c30;
  --mdc-checkbox-selected-hover-state-layer-color: #a33c30;
  --mdc-checkbox-selected-pressed-state-layer-color: #a33c30;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

html {
  --mdc-checkbox-state-layer-size: 40px;
  --mat-checkbox-touch-target-display: block;
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-font: Open Sans;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0179em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
}

html {
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #232323;
  --mat-text-button-state-layer-color: #232323;
  --mat-text-button-ripple-color: rgba(35, 35, 35, 0.1);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #2ec610;
  --mat-text-button-state-layer-color: #2ec610;
  --mat-text-button-ripple-color: rgba(46, 198, 16, 0.1);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #a33c30;
  --mat-text-button-state-layer-color: #a33c30;
  --mat-text-button-ripple-color: rgba(163, 60, 48, 0.1);
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #232323;
  --mdc-filled-button-label-text-color: #efefef;
  --mat-filled-button-state-layer-color: #efefef;
  --mat-filled-button-ripple-color: rgba(239, 239, 239, 0.1);
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #2ec610;
  --mdc-filled-button-label-text-color: #232323;
  --mat-filled-button-state-layer-color: #232323;
  --mat-filled-button-ripple-color: rgba(35, 35, 35, 0.1);
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #a33c30;
  --mdc-filled-button-label-text-color: #efefef;
  --mat-filled-button-state-layer-color: #efefef;
  --mat-filled-button-ripple-color: rgba(239, 239, 239, 0.1);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #232323;
  --mdc-protected-button-label-text-color: #efefef;
  --mat-protected-button-state-layer-color: #efefef;
  --mat-protected-button-ripple-color: rgba(239, 239, 239, 0.1);
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #2ec610;
  --mdc-protected-button-label-text-color: #232323;
  --mat-protected-button-state-layer-color: #232323;
  --mat-protected-button-ripple-color: rgba(35, 35, 35, 0.1);
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #a33c30;
  --mdc-protected-button-label-text-color: #efefef;
  --mat-protected-button-state-layer-color: #efefef;
  --mat-protected-button-ripple-color: rgba(239, 239, 239, 0.1);
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #232323;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #232323;
  --mat-outlined-button-ripple-color: rgba(35, 35, 35, 0.1);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #2ec610;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #2ec610;
  --mat-outlined-button-ripple-color: rgba(46, 198, 16, 0.1);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #a33c30;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #a33c30;
  --mat-outlined-button-ripple-color: rgba(163, 60, 48, 0.1);
}

html {
  --mdc-text-button-container-height: 36px;
  --mdc-filled-button-container-height: 36px;
  --mdc-outlined-button-container-height: 36px;
  --mdc-protected-button-container-height: 36px;
  --mat-text-button-touch-target-display: block;
  --mat-filled-button-touch-target-display: block;
  --mat-protected-button-touch-target-display: block;
  --mat-outlined-button-touch-target-display: block;
}

html {
  --mdc-text-button-label-text-font: Roboto, sans-serif;
  --mdc-text-button-label-text-size: 14px;
  --mdc-text-button-label-text-tracking: 0.0892857143em;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-label-text-transform: none;
  --mdc-filled-button-label-text-font: Roboto, sans-serif;
  --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-tracking: 0.0892857143em;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-label-text-transform: none;
  --mdc-outlined-button-label-text-font: Roboto, sans-serif;
  --mdc-outlined-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: 0.0892857143em;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-label-text-transform: none;
  --mdc-protected-button-label-text-font: Roboto, sans-serif;
  --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-tracking: 0.0892857143em;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-label-text-transform: none;
}

html {
  --mdc-icon-button-icon-size: 24px;
}

html {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
}
html .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #232323;
  --mat-icon-button-state-layer-color: #232323;
  --mat-icon-button-ripple-color: rgba(35, 35, 35, 0.1);
}
html .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #2ec610;
  --mat-icon-button-state-layer-color: #2ec610;
  --mat-icon-button-ripple-color: rgba(46, 198, 16, 0.1);
}
html .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #a33c30;
  --mat-icon-button-state-layer-color: #a33c30;
  --mat-icon-button-ripple-color: rgba(163, 60, 48, 0.1);
}

html {
  --mat-icon-button-touch-target-display: block;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

html {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
}

html {
  --mdc-fab-container-color: white;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
}
html .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #232323;
  --mat-fab-foreground-color: #efefef;
  --mat-fab-state-layer-color: #efefef;
  --mat-fab-ripple-color: rgba(239, 239, 239, 0.1);
}
html .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #2ec610;
  --mat-fab-foreground-color: #232323;
  --mat-fab-state-layer-color: #232323;
  --mat-fab-ripple-color: rgba(35, 35, 35, 0.1);
}
html .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #a33c30;
  --mat-fab-foreground-color: #efefef;
  --mat-fab-state-layer-color: #efefef;
  --mat-fab-ripple-color: rgba(239, 239, 239, 0.1);
}
html .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #232323;
  --mat-fab-small-foreground-color: #efefef;
  --mat-fab-small-state-layer-color: #efefef;
  --mat-fab-small-ripple-color: rgba(239, 239, 239, 0.1);
}
html .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #2ec610;
  --mat-fab-small-foreground-color: #232323;
  --mat-fab-small-state-layer-color: #232323;
  --mat-fab-small-ripple-color: rgba(35, 35, 35, 0.1);
}
html .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #a33c30;
  --mat-fab-small-foreground-color: #efefef;
  --mat-fab-small-state-layer-color: #efefef;
  --mat-fab-small-ripple-color: rgba(239, 239, 239, 0.1);
}

html {
  --mat-fab-touch-target-display: block;
  --mat-fab-small-touch-target-display: block;
}

html {
  --mdc-extended-fab-label-text-font: Roboto, sans-serif;
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: 0.0892857143em;
  --mdc-extended-fab-label-text-weight: 500;
}

html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #2ec610;
}

html {
  --mdc-snackbar-supporting-text-font: Open Sans;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mat-table-header-headline-font: Open Sans;
  --mat-table-header-headline-line-height: 24px;
  --mat-table-header-headline-size: 15px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: 0.0067em;
  --mat-table-row-item-label-text-font: Open Sans;
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: 0.0179em;
  --mat-table-footer-supporting-text-font: Open Sans;
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: 0.0179em;
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mdc-circular-progress-active-indicator-color: #232323;
}
html .mat-accent {
  --mdc-circular-progress-active-indicator-color: #2ec610;
}
html .mat-warn {
  --mdc-circular-progress-active-indicator-color: #a33c30;
}

html {
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
}

html {
  --mat-badge-background-color: #232323;
  --mat-badge-text-color: #efefef;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #2ec610;
  --mat-badge-text-color: rgba(35, 35, 35, 0.87);
}

.mat-badge-warn {
  --mat-badge-background-color: #a33c30;
  --mat-badge-text-color: #efefef;
}

html {
  --mat-badge-text-font: Open Sans;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

html {
  --mat-bottom-sheet-container-shape: 4px;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-bottom-sheet-container-text-font: Open Sans;
  --mat-bottom-sheet-container-text-line-height: 20px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: 0.0179em;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-legacy-button-toggle-label-text-font: Open Sans;
  --mat-legacy-button-toggle-label-text-line-height: 24px;
  --mat-legacy-button-toggle-label-text-size: 14px;
  --mat-legacy-button-toggle-label-text-tracking: 0.0179em;
  --mat-legacy-button-toggle-label-text-weight: 500;
  --mat-standard-button-toggle-label-text-font: Open Sans;
  --mat-standard-button-toggle-label-text-line-height: 24px;
  --mat-standard-button-toggle-label-text-size: 14px;
  --mat-standard-button-toggle-label-text-tracking: 0.0179em;
  --mat-standard-button-toggle-label-text-weight: 500;
}

html {
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: #efefef;
  --mat-datepicker-calendar-date-selected-state-background-color: #232323;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(35, 35, 35, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #efefef;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(35, 35, 35, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(35, 35, 35, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #232323;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(35, 35, 35, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: rgba(35, 35, 35, 0.87);
  --mat-datepicker-calendar-date-selected-state-background-color: #2ec610;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(46, 198, 16, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: rgba(35, 35, 35, 0.87);
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(46, 198, 16, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(46, 198, 16, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(46, 198, 16, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: #efefef;
  --mat-datepicker-calendar-date-selected-state-background-color: #a33c30;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(163, 60, 48, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #efefef;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(163, 60, 48, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(163, 60, 48, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(163, 60, 48, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #2ec610;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #a33c30;
}

.mat-calendar-controls {
  --mat-icon-button-touch-target-display: none;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

html {
  --mat-datepicker-calendar-text-font: Open Sans;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 500;
}

html {
  --mat-divider-width: 1px;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-expansion-header-text-font: Open Sans;
  --mat-expansion-header-text-size: 15px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Open Sans;
  --mat-expansion-container-text-line-height: 20px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: 0.0179em;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #232323;
}
.mat-icon.mat-accent {
  --mat-icon-color: #2ec610;
}
.mat-icon.mat-warn {
  --mat-icon-color: #a33c30;
}

html {
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: #efefef;
  --mat-stepper-header-selected-state-icon-background-color: #232323;
  --mat-stepper-header-selected-state-icon-foreground-color: #efefef;
  --mat-stepper-header-done-state-icon-background-color: #232323;
  --mat-stepper-header-done-state-icon-foreground-color: #efefef;
  --mat-stepper-header-edit-state-icon-background-color: #232323;
  --mat-stepper-header-edit-state-icon-foreground-color: #efefef;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #a33c30;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #a33c30;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: rgba(35, 35, 35, 0.87);
  --mat-stepper-header-selected-state-icon-background-color: #2ec610;
  --mat-stepper-header-selected-state-icon-foreground-color: rgba(35, 35, 35, 0.87);
  --mat-stepper-header-done-state-icon-background-color: #2ec610;
  --mat-stepper-header-done-state-icon-foreground-color: rgba(35, 35, 35, 0.87);
  --mat-stepper-header-edit-state-icon-background-color: #2ec610;
  --mat-stepper-header-edit-state-icon-foreground-color: rgba(35, 35, 35, 0.87);
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: #efefef;
  --mat-stepper-header-selected-state-icon-background-color: #a33c30;
  --mat-stepper-header-selected-state-icon-foreground-color: #efefef;
  --mat-stepper-header-done-state-icon-background-color: #a33c30;
  --mat-stepper-header-done-state-icon-foreground-color: #efefef;
  --mat-stepper-header-edit-state-icon-background-color: #a33c30;
  --mat-stepper-header-edit-state-icon-foreground-color: #efefef;
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-stepper-container-text-font: Open Sans;
  --mat-stepper-header-label-text-font: Open Sans;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 14px;
  --mat-stepper-header-selected-state-label-text-size: 14px;
  --mat-stepper-header-selected-state-label-text-weight: 500;
}

html {
  --mat-sort-arrow-color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #232323;
  --mat-toolbar-container-text-color: #efefef;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #2ec610;
  --mat-toolbar-container-text-color: rgba(35, 35, 35, 0.87);
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #a33c30;
  --mat-toolbar-container-text-color: #efefef;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-toolbar-title-text-font: Open Sans;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: 0.0075em;
  --mat-toolbar-title-text-weight: 500;
}

html {
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mat-tree-node-min-height: 48px;
}

html {
  --mat-tree-node-text-font: Open Sans;
  --mat-tree-node-text-size: 14px;
  --mat-tree-node-text-weight: 400;
}

.theme-alternate {
  --mat-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-option-selected-state-label-text-color: #232323;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-optgroup-label-text-color: white;
  --mat-full-pseudo-checkbox-selected-icon-color: #2ec610;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #2ec610;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
  --mat-app-background-color: #303030;
  --mat-app-text-color: white;
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: #424242;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: #424242;
  --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(255, 255, 255, 0.7);
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #232323;
  --mdc-filled-text-field-focus-active-indicator-color: #232323;
  --mdc-filled-text-field-focus-label-text-color: rgba(35, 35, 35, 0.87);
  --mdc-filled-text-field-container-color: #4a4a4a;
  --mdc-filled-text-field-disabled-container-color: #464646;
  --mdc-filled-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #a33c30;
  --mdc-filled-text-field-error-focus-label-text-color: #a33c30;
  --mdc-filled-text-field-error-label-text-color: #a33c30;
  --mdc-filled-text-field-error-caret-color: #a33c30;
  --mdc-filled-text-field-active-indicator-color: rgba(255, 255, 255, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(255, 255, 255, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #a33c30;
  --mdc-filled-text-field-error-focus-active-indicator-color: #a33c30;
  --mdc-filled-text-field-error-hover-active-indicator-color: #a33c30;
  --mdc-outlined-text-field-caret-color: #232323;
  --mdc-outlined-text-field-focus-outline-color: #232323;
  --mdc-outlined-text-field-focus-label-text-color: rgba(35, 35, 35, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-error-caret-color: #a33c30;
  --mdc-outlined-text-field-error-focus-label-text-color: #a33c30;
  --mdc-outlined-text-field-error-label-text-color: #a33c30;
  --mdc-outlined-text-field-error-hover-label-text-color: #a33c30;
  --mdc-outlined-text-field-outline-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(255, 255, 255, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #a33c30;
  --mdc-outlined-text-field-error-hover-outline-color: #a33c30;
  --mdc-outlined-text-field-error-outline-color: #a33c30;
  --mat-form-field-focus-select-arrow-color: rgba(35, 35, 35, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(255, 255, 255, 0.38);
  --mat-form-field-state-layer-color: rgba(255, 255, 255, 0.87);
  --mat-form-field-error-text-color: #a33c30;
  --mat-form-field-select-option-text-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-select-disabled-option-text-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.08;
  --mat-form-field-focus-state-layer-opacity: 0.24;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: #424242;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(35, 35, 35, 0.87);
  --mat-select-invalid-arrow-color: rgba(163, 60, 48, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: #424242;
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: #424242;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
  --mdc-switch-selected-focus-handle-color: #232323;
  --mdc-switch-selected-hover-handle-color: #232323;
  --mdc-switch-selected-pressed-handle-color: #232323;
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-icon-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-color: #232323;
  --mdc-slider-focus-handle-color: #232323;
  --mdc-slider-hover-handle-color: #232323;
  --mdc-slider-active-track-color: #232323;
  --mdc-slider-inactive-track-color: #232323;
  --mdc-slider-with-tick-marks-inactive-container-color: #232323;
  --mdc-slider-with-tick-marks-active-container-color: #efefef;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-label-container-color: #fff;
  --mdc-slider-label-label-text-color: #000;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: #232323;
  --mat-slider-hover-state-layer-color: rgba(35, 35, 35, 0.05);
  --mat-slider-focus-state-layer-color: rgba(35, 35, 35, 0.2);
  --mat-slider-value-indicator-opacity: 0.9;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-item-label-text-color: white;
  --mat-menu-item-icon-color: white;
  --mat-menu-item-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-container-color: #424242;
  --mat-menu-divider-color: rgba(255, 255, 255, 0.12);
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 0;
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: rgba(255, 255, 255, 0.7);
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
  --mat-paginator-container-text-color: rgba(255, 255, 255, 0.87);
  --mat-paginator-container-background-color: #424242;
  --mat-paginator-enabled-icon-color: rgba(255, 255, 255, 0.54);
  --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.12);
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #232323;
  --mdc-checkbox-selected-focus-icon-color: #2ec610;
  --mdc-checkbox-selected-hover-icon-color: #2ec610;
  --mdc-checkbox-selected-icon-color: #2ec610;
  --mdc-checkbox-selected-pressed-icon-color: #2ec610;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #2ec610;
  --mdc-checkbox-selected-hover-state-layer-color: #2ec610;
  --mdc-checkbox-selected-pressed-state-layer-color: #2ec610;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
  --mat-checkbox-disabled-label-color: rgba(255, 255, 255, 0.5);
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: white;
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mat-text-button-state-layer-color: white;
  --mat-text-button-disabled-state-layer-color: white;
  --mat-text-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.08;
  --mat-text-button-focus-state-layer-opacity: 0.24;
  --mat-text-button-pressed-state-layer-opacity: 0.24;
  --mdc-filled-button-container-color: #424242;
  --mdc-filled-button-label-text-color: white;
  --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-disabled-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.08;
  --mat-filled-button-focus-state-layer-opacity: 0.24;
  --mat-filled-button-pressed-state-layer-opacity: 0.24;
  --mdc-protected-button-container-color: #424242;
  --mdc-protected-button-label-text-color: white;
  --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-disabled-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.08;
  --mat-protected-button-focus-state-layer-opacity: 0.24;
  --mat-protected-button-pressed-state-layer-opacity: 0.24;
  --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-label-text-color: white;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mat-outlined-button-state-layer-color: white;
  --mat-outlined-button-disabled-state-layer-color: white;
  --mat-outlined-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.08;
  --mat-outlined-button-focus-state-layer-opacity: 0.24;
  --mat-outlined-button-pressed-state-layer-opacity: 0.24;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
  --mat-icon-button-state-layer-color: white;
  --mat-icon-button-disabled-state-layer-color: white;
  --mat-icon-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.08;
  --mat-icon-button-focus-state-layer-opacity: 0.24;
  --mat-icon-button-pressed-state-layer-opacity: 0.24;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-fab-container-color: #424242;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-disabled-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.08;
  --mat-fab-focus-state-layer-opacity: 0.24;
  --mat-fab-pressed-state-layer-opacity: 0.24;
  --mat-fab-disabled-state-container-color: rgba(255, 255, 255, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(255, 255, 255, 0.5);
  --mdc-fab-small-container-color: #424242;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-disabled-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.08;
  --mat-fab-small-focus-state-layer-opacity: 0.24;
  --mat-fab-small-pressed-state-layer-opacity: 0.24;
  --mat-fab-small-disabled-state-container-color: rgba(255, 255, 255, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(255, 255, 255, 0.5);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #d9d9d9;
  --mdc-snackbar-supporting-text-color: rgba(66, 66, 66, 0.87);
  --mat-snack-bar-button-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: #424242;
  --mat-table-header-headline-color: white;
  --mat-table-row-item-label-text-color: white;
  --mat-table-row-item-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #232323;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #232323;
  --mat-badge-text-color: #efefef;
  --mat-badge-disabled-state-background-color: #6e6e6e;
  --mat-badge-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: white;
  --mat-bottom-sheet-container-background-color: #424242;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: rgba(255, 255, 255, 0.5);
  --mat-legacy-button-toggle-state-layer-color: rgba(255, 255, 255, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(255, 255, 255, 0.7);
  --mat-legacy-button-toggle-selected-state-background-color: #212121;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-legacy-button-toggle-disabled-state-background-color: black;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #424242;
  --mat-standard-button-toggle-text-color: white;
  --mat-standard-button-toggle-background-color: #424242;
  --mat-standard-button-toggle-state-layer-color: white;
  --mat-standard-button-toggle-selected-state-background-color: #212121;
  --mat-standard-button-toggle-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-standard-button-toggle-disabled-state-background-color: #424242;
  --mat-standard-button-toggle-disabled-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #424242;
  --mat-standard-button-toggle-divider-color: #595959;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: #efefef;
  --mat-datepicker-calendar-date-selected-state-background-color: #232323;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(35, 35, 35, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #efefef;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(35, 35, 35, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(35, 35, 35, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #232323;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(35, 35, 35, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: white;
  --mat-datepicker-calendar-body-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-datepicker-calendar-period-button-text-color: white;
  --mat-datepicker-calendar-period-button-icon-color: white;
  --mat-datepicker-calendar-navigation-button-icon-color: white;
  --mat-datepicker-calendar-header-divider-color: rgba(255, 255, 255, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(255, 255, 255, 0.7);
  --mat-datepicker-calendar-date-today-outline-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(255, 255, 255, 0.3);
  --mat-datepicker-calendar-date-text-color: white;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(255, 255, 255, 0.24);
  --mat-datepicker-range-input-separator-color: white;
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-container-background-color: #424242;
  --mat-datepicker-calendar-container-text-color: white;
  --mat-divider-width: 1px;
  --mat-divider-color: rgba(255, 255, 255, 0.12);
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: #424242;
  --mat-expansion-container-text-color: white;
  --mat-expansion-actions-divider-color: rgba(255, 255, 255, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-expansion-header-text-color: white;
  --mat-expansion-header-description-color: rgba(255, 255, 255, 0.7);
  --mat-expansion-header-indicator-color: rgba(255, 255, 255, 0.7);
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: rgba(255, 255, 255, 0.12);
  --mat-sidenav-container-background-color: #424242;
  --mat-sidenav-container-text-color: white;
  --mat-sidenav-content-background-color: #303030;
  --mat-sidenav-content-text-color: white;
  --mat-sidenav-scrim-color: rgba(189, 189, 189, 0.6);
  --mat-stepper-header-icon-foreground-color: #efefef;
  --mat-stepper-header-selected-state-icon-background-color: #232323;
  --mat-stepper-header-selected-state-icon-foreground-color: #efefef;
  --mat-stepper-header-done-state-icon-background-color: #232323;
  --mat-stepper-header-done-state-icon-foreground-color: #efefef;
  --mat-stepper-header-edit-state-icon-background-color: #232323;
  --mat-stepper-header-edit-state-icon-foreground-color: #efefef;
  --mat-stepper-container-color: #424242;
  --mat-stepper-line-color: rgba(255, 255, 255, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-optional-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-selected-state-label-text-color: white;
  --mat-stepper-header-error-state-label-text-color: #a33c30;
  --mat-stepper-header-icon-background-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-error-state-icon-foreground-color: #a33c30;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #c6c6c6;
  --mat-toolbar-container-background-color: #212121;
  --mat-toolbar-container-text-color: white;
  --mat-tree-container-background-color: #424242;
  --mat-tree-node-text-color: white;
}
.theme-alternate .mat-accent {
  --mat-option-selected-state-label-text-color: #2ec610;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.theme-alternate .mat-warn {
  --mat-option-selected-state-label-text-color: #a33c30;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.theme-alternate .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #232323;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #232323;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-alternate .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #2ec610;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #2ec610;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-alternate .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #a33c30;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #a33c30;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.theme-alternate .mat-elevation-z0, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z1, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z2, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z3, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z4, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z5, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z6, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z7, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z8, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z9, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z10, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z11, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z12, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z13, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z14, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z15, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z16, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z17, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z18, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z19, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z20, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z21, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z22, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z23, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z24, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-alternate .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #232323;
  --mdc-linear-progress-track-color: rgba(35, 35, 35, 0.25);
}
.theme-alternate .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #2ec610;
  --mdc-linear-progress-track-color: rgba(46, 198, 16, 0.25);
}
.theme-alternate .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #a33c30;
  --mdc-linear-progress-track-color: rgba(163, 60, 48, 0.25);
}
.theme-alternate .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #2ec610;
  --mdc-filled-text-field-focus-active-indicator-color: #2ec610;
  --mdc-filled-text-field-focus-label-text-color: rgba(46, 198, 16, 0.87);
  --mdc-outlined-text-field-caret-color: #2ec610;
  --mdc-outlined-text-field-focus-outline-color: #2ec610;
  --mdc-outlined-text-field-focus-label-text-color: rgba(46, 198, 16, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(46, 198, 16, 0.87);
}
.theme-alternate .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #a33c30;
  --mdc-filled-text-field-focus-active-indicator-color: #a33c30;
  --mdc-filled-text-field-focus-label-text-color: rgba(163, 60, 48, 0.87);
  --mdc-outlined-text-field-caret-color: #a33c30;
  --mdc-outlined-text-field-focus-outline-color: #a33c30;
  --mdc-outlined-text-field-focus-label-text-color: rgba(163, 60, 48, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(163, 60, 48, 0.87);
}
.theme-alternate .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: #424242;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(46, 198, 16, 0.87);
  --mat-select-invalid-arrow-color: rgba(163, 60, 48, 0.87);
}
.theme-alternate .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: #424242;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(163, 60, 48, 0.87);
  --mat-select-invalid-arrow-color: rgba(163, 60, 48, 0.87);
}
.theme-alternate .mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.theme-alternate .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-elevated-container-color: #595959;
  --mdc-chip-elevated-selected-container-color: #595959;
  --mdc-chip-elevated-disabled-container-color: #595959;
  --mdc-chip-flat-disabled-selected-container-color: #595959;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-selected-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
  --mat-chip-selected-disabled-trailing-icon-color: #fafafa;
  --mat-chip-selected-trailing-icon-color: #fafafa;
}
.theme-alternate .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .theme-alternate .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: #efefef;
  --mdc-chip-elevated-container-color: #232323;
  --mdc-chip-elevated-selected-container-color: #232323;
  --mdc-chip-elevated-disabled-container-color: #232323;
  --mdc-chip-flat-disabled-selected-container-color: #232323;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #efefef;
  --mdc-chip-selected-label-text-color: #efefef;
  --mdc-chip-with-icon-icon-color: #efefef;
  --mdc-chip-with-icon-disabled-icon-color: #efefef;
  --mdc-chip-with-icon-selected-icon-color: #efefef;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #efefef;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #efefef;
  --mat-chip-selected-disabled-trailing-icon-color: #efefef;
  --mat-chip-selected-trailing-icon-color: #efefef;
}
.theme-alternate .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .theme-alternate .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: rgba(35, 35, 35, 0.87);
  --mdc-chip-elevated-container-color: #2ec610;
  --mdc-chip-elevated-selected-container-color: #2ec610;
  --mdc-chip-elevated-disabled-container-color: #2ec610;
  --mdc-chip-flat-disabled-selected-container-color: #2ec610;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: rgba(35, 35, 35, 0.87);
  --mdc-chip-selected-label-text-color: rgba(35, 35, 35, 0.87);
  --mdc-chip-with-icon-icon-color: rgba(35, 35, 35, 0.87);
  --mdc-chip-with-icon-disabled-icon-color: rgba(35, 35, 35, 0.87);
  --mdc-chip-with-icon-selected-icon-color: rgba(35, 35, 35, 0.87);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: rgba(35, 35, 35, 0.87);
  --mdc-chip-with-trailing-icon-trailing-icon-color: rgba(35, 35, 35, 0.87);
  --mat-chip-selected-disabled-trailing-icon-color: rgba(35, 35, 35, 0.87);
  --mat-chip-selected-trailing-icon-color: rgba(35, 35, 35, 0.87);
}
.theme-alternate .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .theme-alternate .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: #efefef;
  --mdc-chip-elevated-container-color: #a33c30;
  --mdc-chip-elevated-selected-container-color: #a33c30;
  --mdc-chip-elevated-disabled-container-color: #a33c30;
  --mdc-chip-flat-disabled-selected-container-color: #a33c30;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #efefef;
  --mdc-chip-selected-label-text-color: #efefef;
  --mdc-chip-with-icon-icon-color: #efefef;
  --mdc-chip-with-icon-disabled-icon-color: #efefef;
  --mdc-chip-with-icon-selected-icon-color: #efefef;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #efefef;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #efefef;
  --mat-chip-selected-disabled-trailing-icon-color: #efefef;
  --mat-chip-selected-trailing-icon-color: #efefef;
}
.theme-alternate .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: white;
}
.theme-alternate .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-handle-color: #2ec610;
  --mdc-switch-selected-hover-handle-color: #2ec610;
  --mdc-switch-selected-pressed-handle-color: #2ec610;
}
.theme-alternate .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-handle-color: #541f19;
  --mdc-switch-selected-hover-handle-color: #541f19;
  --mdc-switch-selected-pressed-handle-color: #541f19;
}
.theme-alternate .mat-mdc-radio-button {
  --mdc-form-field-label-text-color: white;
}
.theme-alternate .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #232323;
  --mdc-radio-selected-hover-icon-color: #232323;
  --mdc-radio-selected-icon-color: #232323;
  --mdc-radio-selected-pressed-icon-color: #232323;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #232323;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #2ec610;
  --mdc-radio-selected-hover-icon-color: #2ec610;
  --mdc-radio-selected-icon-color: #2ec610;
  --mdc-radio-selected-pressed-icon-color: #2ec610;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #2ec610;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #a33c30;
  --mdc-radio-selected-hover-icon-color: #a33c30;
  --mdc-radio-selected-icon-color: #a33c30;
  --mdc-radio-selected-pressed-icon-color: #a33c30;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #a33c30;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-accent {
  --mat-slider-ripple-color: #2ec610;
  --mat-slider-hover-state-layer-color: rgba(46, 198, 16, 0.05);
  --mat-slider-focus-state-layer-color: rgba(46, 198, 16, 0.2);
  --mdc-slider-handle-color: #2ec610;
  --mdc-slider-focus-handle-color: #2ec610;
  --mdc-slider-hover-handle-color: #2ec610;
  --mdc-slider-active-track-color: #2ec610;
  --mdc-slider-inactive-track-color: #2ec610;
  --mdc-slider-with-tick-marks-inactive-container-color: #2ec610;
  --mdc-slider-with-tick-marks-active-container-color: rgba(35, 35, 35, 0.87);
}
.theme-alternate .mat-warn {
  --mat-slider-ripple-color: #a33c30;
  --mat-slider-hover-state-layer-color: rgba(163, 60, 48, 0.05);
  --mat-slider-focus-state-layer-color: rgba(163, 60, 48, 0.2);
  --mdc-slider-handle-color: #a33c30;
  --mdc-slider-focus-handle-color: #a33c30;
  --mdc-slider-hover-handle-color: #a33c30;
  --mdc-slider-active-track-color: #a33c30;
  --mdc-slider-inactive-track-color: #a33c30;
  --mdc-slider-with-tick-marks-inactive-container-color: #a33c30;
  --mdc-slider-with-tick-marks-active-container-color: #efefef;
}
.theme-alternate .mdc-list-item__start,
.theme-alternate .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #232323;
  --mdc-radio-selected-hover-icon-color: #232323;
  --mdc-radio-selected-icon-color: #232323;
  --mdc-radio-selected-pressed-icon-color: #232323;
}
.theme-alternate .mat-accent .mdc-list-item__start,
.theme-alternate .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #2ec610;
  --mdc-radio-selected-hover-icon-color: #2ec610;
  --mdc-radio-selected-icon-color: #2ec610;
  --mdc-radio-selected-pressed-icon-color: #2ec610;
}
.theme-alternate .mat-warn .mdc-list-item__start,
.theme-alternate .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #a33c30;
  --mdc-radio-selected-hover-icon-color: #a33c30;
  --mdc-radio-selected-icon-color: #a33c30;
  --mdc-radio-selected-pressed-icon-color: #a33c30;
}
.theme-alternate .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #efefef;
  --mdc-checkbox-selected-focus-icon-color: #232323;
  --mdc-checkbox-selected-hover-icon-color: #232323;
  --mdc-checkbox-selected-icon-color: #232323;
  --mdc-checkbox-selected-pressed-icon-color: #232323;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #232323;
  --mdc-checkbox-selected-hover-state-layer-color: #232323;
  --mdc-checkbox-selected-pressed-state-layer-color: #232323;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-alternate .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #232323;
  --mdc-checkbox-selected-focus-icon-color: #2ec610;
  --mdc-checkbox-selected-hover-icon-color: #2ec610;
  --mdc-checkbox-selected-icon-color: #2ec610;
  --mdc-checkbox-selected-pressed-icon-color: #2ec610;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #2ec610;
  --mdc-checkbox-selected-hover-state-layer-color: #2ec610;
  --mdc-checkbox-selected-pressed-state-layer-color: #2ec610;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-alternate .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #efefef;
  --mdc-checkbox-selected-focus-icon-color: #a33c30;
  --mdc-checkbox-selected-hover-icon-color: #a33c30;
  --mdc-checkbox-selected-icon-color: #a33c30;
  --mdc-checkbox-selected-pressed-icon-color: #a33c30;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #a33c30;
  --mdc-checkbox-selected-hover-state-layer-color: #a33c30;
  --mdc-checkbox-selected-pressed-state-layer-color: #a33c30;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-alternate .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-alternate .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #232323;
}
.theme-alternate .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-alternate .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #232323;
}
.theme-alternate .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-alternate .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-alternate .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.theme-alternate .mat-mdc-tab-group, .theme-alternate .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #232323;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: white;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #232323;
  --mat-tab-header-active-ripple-color: #232323;
  --mat-tab-header-inactive-ripple-color: #232323;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #232323;
  --mat-tab-header-active-hover-label-text-color: #232323;
  --mat-tab-header-active-focus-indicator-color: #232323;
  --mat-tab-header-active-hover-indicator-color: #232323;
}
.theme-alternate .mat-mdc-tab-group.mat-accent, .theme-alternate .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #2ec610;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: white;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #2ec610;
  --mat-tab-header-active-ripple-color: #2ec610;
  --mat-tab-header-inactive-ripple-color: #2ec610;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #2ec610;
  --mat-tab-header-active-hover-label-text-color: #2ec610;
  --mat-tab-header-active-focus-indicator-color: #2ec610;
  --mat-tab-header-active-hover-indicator-color: #2ec610;
}
.theme-alternate .mat-mdc-tab-group.mat-warn, .theme-alternate .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #a33c30;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: white;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #a33c30;
  --mat-tab-header-active-ripple-color: #a33c30;
  --mat-tab-header-inactive-ripple-color: #a33c30;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #a33c30;
  --mat-tab-header-active-hover-label-text-color: #a33c30;
  --mat-tab-header-active-focus-indicator-color: #a33c30;
  --mat-tab-header-active-hover-indicator-color: #a33c30;
}
.theme-alternate .mat-mdc-tab-group.mat-background-primary, .theme-alternate .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #232323;
  --mat-tab-header-with-background-foreground-color: #efefef;
}
.theme-alternate .mat-mdc-tab-group.mat-background-accent, .theme-alternate .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #2ec610;
  --mat-tab-header-with-background-foreground-color: rgba(35, 35, 35, 0.87);
}
.theme-alternate .mat-mdc-tab-group.mat-background-warn, .theme-alternate .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #a33c30;
  --mat-tab-header-with-background-foreground-color: #efefef;
}
.theme-alternate .mat-mdc-checkbox {
  --mdc-form-field-label-text-color: white;
}
.theme-alternate .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #efefef;
  --mdc-checkbox-selected-focus-icon-color: #232323;
  --mdc-checkbox-selected-hover-icon-color: #232323;
  --mdc-checkbox-selected-icon-color: #232323;
  --mdc-checkbox-selected-pressed-icon-color: #232323;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #232323;
  --mdc-checkbox-selected-hover-state-layer-color: #232323;
  --mdc-checkbox-selected-pressed-state-layer-color: #232323;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-alternate .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #efefef;
  --mdc-checkbox-selected-focus-icon-color: #a33c30;
  --mdc-checkbox-selected-hover-icon-color: #a33c30;
  --mdc-checkbox-selected-icon-color: #a33c30;
  --mdc-checkbox-selected-pressed-icon-color: #a33c30;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #a33c30;
  --mdc-checkbox-selected-hover-state-layer-color: #a33c30;
  --mdc-checkbox-selected-pressed-state-layer-color: #a33c30;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-alternate .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #232323;
  --mat-text-button-state-layer-color: #232323;
  --mat-text-button-ripple-color: rgba(35, 35, 35, 0.1);
}
.theme-alternate .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #2ec610;
  --mat-text-button-state-layer-color: #2ec610;
  --mat-text-button-ripple-color: rgba(46, 198, 16, 0.1);
}
.theme-alternate .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #a33c30;
  --mat-text-button-state-layer-color: #a33c30;
  --mat-text-button-ripple-color: rgba(163, 60, 48, 0.1);
}
.theme-alternate .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #232323;
  --mdc-filled-button-label-text-color: #efefef;
  --mat-filled-button-state-layer-color: #efefef;
  --mat-filled-button-ripple-color: rgba(239, 239, 239, 0.1);
}
.theme-alternate .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #2ec610;
  --mdc-filled-button-label-text-color: #232323;
  --mat-filled-button-state-layer-color: #232323;
  --mat-filled-button-ripple-color: rgba(35, 35, 35, 0.1);
}
.theme-alternate .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #a33c30;
  --mdc-filled-button-label-text-color: #efefef;
  --mat-filled-button-state-layer-color: #efefef;
  --mat-filled-button-ripple-color: rgba(239, 239, 239, 0.1);
}
.theme-alternate .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #232323;
  --mdc-protected-button-label-text-color: #efefef;
  --mat-protected-button-state-layer-color: #efefef;
  --mat-protected-button-ripple-color: rgba(239, 239, 239, 0.1);
}
.theme-alternate .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #2ec610;
  --mdc-protected-button-label-text-color: #232323;
  --mat-protected-button-state-layer-color: #232323;
  --mat-protected-button-ripple-color: rgba(35, 35, 35, 0.1);
}
.theme-alternate .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #a33c30;
  --mdc-protected-button-label-text-color: #efefef;
  --mat-protected-button-state-layer-color: #efefef;
  --mat-protected-button-ripple-color: rgba(239, 239, 239, 0.1);
}
.theme-alternate .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #232323;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mat-outlined-button-state-layer-color: #232323;
  --mat-outlined-button-ripple-color: rgba(35, 35, 35, 0.1);
}
.theme-alternate .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #2ec610;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mat-outlined-button-state-layer-color: #2ec610;
  --mat-outlined-button-ripple-color: rgba(46, 198, 16, 0.1);
}
.theme-alternate .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #a33c30;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mat-outlined-button-state-layer-color: #a33c30;
  --mat-outlined-button-ripple-color: rgba(163, 60, 48, 0.1);
}
.theme-alternate .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #232323;
  --mat-icon-button-state-layer-color: #232323;
  --mat-icon-button-ripple-color: rgba(35, 35, 35, 0.1);
}
.theme-alternate .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #2ec610;
  --mat-icon-button-state-layer-color: #2ec610;
  --mat-icon-button-ripple-color: rgba(46, 198, 16, 0.1);
}
.theme-alternate .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #a33c30;
  --mat-icon-button-state-layer-color: #a33c30;
  --mat-icon-button-ripple-color: rgba(163, 60, 48, 0.1);
}
.theme-alternate .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #232323;
  --mat-fab-foreground-color: #efefef;
  --mat-fab-state-layer-color: #efefef;
  --mat-fab-ripple-color: rgba(239, 239, 239, 0.1);
}
.theme-alternate .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #2ec610;
  --mat-fab-foreground-color: #232323;
  --mat-fab-state-layer-color: #232323;
  --mat-fab-ripple-color: rgba(35, 35, 35, 0.1);
}
.theme-alternate .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #a33c30;
  --mat-fab-foreground-color: #efefef;
  --mat-fab-state-layer-color: #efefef;
  --mat-fab-ripple-color: rgba(239, 239, 239, 0.1);
}
.theme-alternate .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #232323;
  --mat-fab-small-foreground-color: #efefef;
  --mat-fab-small-state-layer-color: #efefef;
  --mat-fab-small-ripple-color: rgba(239, 239, 239, 0.1);
}
.theme-alternate .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #2ec610;
  --mat-fab-small-foreground-color: #232323;
  --mat-fab-small-state-layer-color: #232323;
  --mat-fab-small-ripple-color: rgba(35, 35, 35, 0.1);
}
.theme-alternate .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #a33c30;
  --mat-fab-small-foreground-color: #efefef;
  --mat-fab-small-state-layer-color: #efefef;
  --mat-fab-small-ripple-color: rgba(239, 239, 239, 0.1);
}
.theme-alternate .mat-accent {
  --mdc-circular-progress-active-indicator-color: #2ec610;
}
.theme-alternate .mat-warn {
  --mdc-circular-progress-active-indicator-color: #a33c30;
}
.theme-alternate .mat-badge-accent {
  --mat-badge-background-color: #2ec610;
  --mat-badge-text-color: rgba(35, 35, 35, 0.87);
}
.theme-alternate .mat-badge-warn {
  --mat-badge-background-color: #a33c30;
  --mat-badge-text-color: #efefef;
}
.theme-alternate .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: rgba(35, 35, 35, 0.87);
  --mat-datepicker-calendar-date-selected-state-background-color: #2ec610;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(46, 198, 16, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: rgba(35, 35, 35, 0.87);
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(46, 198, 16, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(46, 198, 16, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(46, 198, 16, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-alternate .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: #efefef;
  --mat-datepicker-calendar-date-selected-state-background-color: #a33c30;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(163, 60, 48, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #efefef;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(163, 60, 48, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(163, 60, 48, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(163, 60, 48, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-alternate .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #2ec610;
}
.theme-alternate .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #a33c30;
}
.theme-alternate .mat-icon.mat-primary {
  --mat-icon-color: #232323;
}
.theme-alternate .mat-icon.mat-accent {
  --mat-icon-color: #2ec610;
}
.theme-alternate .mat-icon.mat-warn {
  --mat-icon-color: #a33c30;
}
.theme-alternate .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: rgba(35, 35, 35, 0.87);
  --mat-stepper-header-selected-state-icon-background-color: #2ec610;
  --mat-stepper-header-selected-state-icon-foreground-color: rgba(35, 35, 35, 0.87);
  --mat-stepper-header-done-state-icon-background-color: #2ec610;
  --mat-stepper-header-done-state-icon-foreground-color: rgba(35, 35, 35, 0.87);
  --mat-stepper-header-edit-state-icon-background-color: #2ec610;
  --mat-stepper-header-edit-state-icon-foreground-color: rgba(35, 35, 35, 0.87);
}
.theme-alternate .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: #efefef;
  --mat-stepper-header-selected-state-icon-background-color: #a33c30;
  --mat-stepper-header-selected-state-icon-foreground-color: #efefef;
  --mat-stepper-header-done-state-icon-background-color: #a33c30;
  --mat-stepper-header-done-state-icon-foreground-color: #efefef;
  --mat-stepper-header-edit-state-icon-background-color: #a33c30;
  --mat-stepper-header-edit-state-icon-foreground-color: #efefef;
}
.theme-alternate .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #232323;
  --mat-toolbar-container-text-color: #efefef;
}
.theme-alternate .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #2ec610;
  --mat-toolbar-container-text-color: rgba(35, 35, 35, 0.87);
}
.theme-alternate .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #a33c30;
  --mat-toolbar-container-text-color: #efefef;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent;
}

.dialog-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.dialog-container .mat-mdc-dialog-content {
  flex-grow: 1;
  max-height: unset;
}

.mat-mdc-dialog-actions {
  padding: 1rem !important;
}

.mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
  background: #2EC610 !important;
}

.mat-mdc-select .mat-mdc-select-trigger .mat-icon {
  font-size: 20px;
  height: 18px;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface,
.mat-mdc-snack-bar-container .mdc-snackbar__label {
  border-radius: 6px;
}

.mat-mdc-snack-bar-container.error .mdc-snackbar__surface,
.mat-mdc-snack-bar-container.error .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled),
.mat-mdc-snack-bar-container.error .mdc-snackbar__label {
  color: #7d2d27;
  background-color: #d8c0be;
}

.mat-mdc-snack-bar-container.warn .mdc-snackbar__surface,
.mat-mdc-snack-bar-container.warn .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled),
.mat-mdc-snack-bar-container.warn .mdc-snackbar__label {
  color: #ECCF4F;
  background-color: white;
}

.mat-mdc-snack-bar-container.success .mdc-snackbar__surface,
.mat-mdc-snack-bar-container.success .mat-mdc-button,
.mat-mdc-snack-bar-container.success .mdc-snackbar__label {
  color: #2EC610;
  background-color: #F2FDED;
}

/*$adminTheme: mat.define-dark-theme((
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn),
));*/
.app-admin-container {
  height: calc(100vh - 64px);
}
.app-admin-container .sidebar-panel {
  height: 100%;
  z-index: 10001;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.app-admin-container .sidebar-panel .navigation-hold {
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.95);
}
.app-admin-container .sidebar-panel ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.back-theme {
  --mat-form-field-container-height: 52px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 14px;
  --mat-form-field-filled-with-label-container-padding-top: 22px;
  --mat-form-field-filled-with-label-container-padding-bottom: 6px;
  /*.mat-dialog-container {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      padding-left: 0;
      padding-right: 0;
    }

    .mat-dialog-container > * {
      flex-direction: column;
      height: 100%;
    }

    mat-dialog-actions.higher-actions {
      min-height: 80px;
      padding: 10px 0 0 0;
    }

    div[mat-dialog-content] {
      // max-height: 80vh;
      margin: 0;
    }

    div[mat-dialog-title], h1[mat-dialog-title], h3[mat-dialog-title] {
      font-weight: 200;
      font-size: 1.5em;
      padding: 0 1em 1em 1em;
      border-bottom: 1px solid mat.$black-12-opacity;
    }

    div[mat-dialog-actions] {
      padding: 1em;
      border-top: 1px solid mat.$black-12-opacity;

      .mat-button {
        margin-right: 0.5em
      }
    }
  */
}
.back-theme .mat-bg-primary,
.back-theme .top-bar:not(.top-bar-white),
.back-theme .chats-wrap .conversations-hold .single-conversation.me .conversation-msg,
.back-theme .ngx-datatable .datatable-footer .datatable-pager .pager .pages.active a,
.back-theme .fileupload-drop-zone.dz-file-over,
.back-theme .cal-open-day-events {
  background: #232323 !important;
  color: #ffffff !important;
}
.back-theme .mat-color-primary,
.back-theme .list-item-active,
.back-theme .sidenav-hold .sidenav li.active > a .mat-icon,
.back-theme .sidenav-hold .sidenav li.active > div > a .mat-icon,
.back-theme .sidenav-hold .sidenav li.active > div > a > span:not(.menuitem-badge),
.back-theme .sidenav-hold .sidenav li.active > a > span {
  color: #232323 !important;
}
.back-theme .topnav ul.menu > li > div.active > a,
.back-theme .topnav ul.menu > li > div.active > div,
.back-theme .sidebar-panel .sidebar-list-item.active > .mat-list-item-content > .sub-menu,
.back-theme .sidenav-hold .sidenav li.active > div > a .mat-icon,
.back-theme .list-item-active {
  border-color: #232323 !important;
}
.back-theme .mat-mdc-raised-button > .mat-icon {
  /*margin-left: 0;
  margin-right: 0;*/
}
.back-theme .sidebar-panel {
  background: url("/assets/images/sidebar-bg.jpg") no-repeat;
  background-size: cover;
}
.back-theme .mat-cell .mat-form-field-infix, .back-theme .mat-header-cell .mat-form-field-infix {
  width: 100%;
}
.back-theme .mat-cell.no-height {
  min-height: 0;
}
.back-theme mat-header-row {
  align-items: flex-start;
}
.back-theme .mat-header-cell {
  flex-direction: column;
  align-items: start;
  padding: 0.8rem;
}
.back-theme .mat-header-cell.text-right {
  justify-content: flex-start;
}
.back-theme .mat-form-field-suffix {
  padding-left: 0.5rem;
}
.back-theme mat-row:hover {
  background: rgba(0, 0, 0, 0.015);
}
.back-theme mat-row::after {
  min-height: 0;
}
.back-theme .mdc-dialog__title {
  padding-top: 1rem;
}
.back-theme .mat-expansion-panel-header-description, .back-theme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.87);
}
.back-theme .table-danger, .back-theme .table-alerted {
  background-color: rgba(163, 60, 48, 0.25);
  color: #a33c30 !important;
}
.back-theme .table-danger .mat-cell, .back-theme .table-danger .text-muted, .back-theme .table-alerted .mat-cell, .back-theme .table-alerted .text-muted {
  color: #a33c30 !important;
}
.back-theme .table-warning {
  background-color: rgba(163, 60, 48, 0.15);
}
.back-theme .table-pre-warning {
  background-color: rgba(163, 60, 48, 0.1);
}
.back-theme .alert-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem;
  border: 1px solid #1166cc;
  background-color: rgba(17, 102, 204, 0.1);
  color: #1166cc;
  margin-top: 0.5em;
  border-radius: 4px;
}
.back-theme .table-success {
  background-color: rgba(0, 255, 0, 0.1);
}
.back-theme .mat-input-element:disabled, .back-theme .back-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.back-theme mat-chip-list.in-table .mat-chip-list-wrapper {
  margin: 0;
}
.back-theme .left-border {
  border-left: 1px solid rgba(0, 0, 0, 0.09);
}
.back-theme .simple-button {
  min-width: auto;
  line-height: 1rem;
  border-bottom: 1px dashed #999;
}
.back-theme .version {
  text-align: center;
  color: rgba(0, 0, 0, 0.54);
  max-height: 36px;
  overflow: hidden;
}
.back-theme .text-bold {
  font-weight: bolder;
}
.back-theme .mat-tab-body-wrapper {
  height: 100%;
}
.back-theme .cdk-tree-node:not(:last-child) li {
  border-bottom: 1px #ededed solid;
}
.back-theme .mat-drawer-container, .back-theme .mat-toolbar, .back-theme .mat-card, .back-theme .mat-table, .back-theme .mat-paginator {
  background-color: #fff;
}
.back-theme .mat-card .mat-divider-horizontal {
  position: static;
}
.back-theme .mdc-data-table__header-cell:empty {
  padding: 0 0.5rem;
}
.back-theme .mdc-data-table__cell, .back-theme .mdc-data-table__header-cell:has(*) {
  padding: 0;
  align-items: center;
}
.back-theme .mdc-data-table__cell > *, .back-theme .mdc-data-table__header-cell:has(*) > * {
  width: -webkit-fill-available;
  padding: 0 0.5rem;
}
.back-theme .mdc-data-table__cell .mat-mdc-form-field-hint-wrapper, .back-theme .mdc-data-table__header-cell:has(*) .mat-mdc-form-field-hint-wrapper {
  padding: 0 0;
  align-items: flex-end;
}
.back-theme .mat-mdc-form-field-infix {
  width: auto;
}
.back-theme .suffix-bottom-line .mat-mdc-form-field-icon-suffix {
  align-self: auto;
}
.back-theme .mat-mdc-form-field .mdc-button, .back-theme .table-actions {
  min-width: auto;
}
.back-theme .mat-mdc-form-field .mdc-button .mat-icon, .back-theme .table-actions .mat-icon {
  margin: 0 4px;
}
.back-theme .mat-mdc-form-field .mdc-button .mdc-button, .back-theme .table-actions .mdc-button {
  min-width: auto;
}
.back-theme .amount-select {
  padding-left: 0px;
}
.back-theme .amount-select .mdc-text-field {
  padding: 0 4px;
}
.back-theme .currency-toggle {
  cursor: pointer;
  border-bottom: 1px dashed gray;
}
.back-theme .icon-select-trigger .mat-mdc-form-field-infix {
  padding-bottom: 0 !important;
}
.back-theme .icon-select-trigger .mat-mdc-form-field-infix .mat-mdc-select-arrow {
  display: none;
}
.back-theme .disabled-card {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0.3;
  background-color: grey;
  border-radius: 3px;
}
.back-theme .disabled-card .mat-icon {
  font-size: 3rem;
  width: auto;
  height: auto;
}
.back-theme .add-item-card {
  background-color: #f2fded;
}

body.front-theme {
  background-color: #fafafa;
}

.front-theme {
  /*.material-icons {
      font-family: 'Material Icons Outlined', 'Material Icons', serif;
  }*/
  color: rgba(0, 0, 0, 0.87);
  background-size: 400px auto;
  background-repeat: repeat;
  /*$adminTheme: mat.define-dark-theme((
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn),
  ));*/
  /*$adminTheme: mat.define-dark-theme((
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn),
  ));*/
  /*$adminTheme: mat.define-dark-theme((
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn),
  ));*/
  /*$adminTheme: mat.define-dark-theme((
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn),
  ));*/
}
.front-theme h1 {
  text-align: left;
  width: 100%;
}
.front-theme h3 {
  margin-top: 2rem;
}
.front-theme a {
  color: #2ec610;
}
.front-theme a:hover {
  color: #23970c;
}
.front-theme .mat-drawer-container {
  background-color: transparent;
}
.front-theme .mat-drawer-container .mat-sidenav a {
  color: #2ec610 !important;
}
.front-theme .mat-drawer-inner-container {
  overflow-x: hidden;
}
.front-theme .mat-button {
  border-radius: 0;
}
.front-theme .mat-warn:not([color=warn]) {
  color: #a33c30;
}
.front-theme .mat-toolbar-single-row {
  padding: 0;
}
.front-theme .mat-bg-accent {
  color: #efefef;
  background-color: #2ec610;
}
.front-theme .mat-bg-primary {
  color: #efefef;
  background-color: #232323;
}
.front-theme .text-accent {
  color: #2ec610;
}
.front-theme .cart-btn, .front-theme .head-btn {
  color: rgba(0, 0, 0, 0.87);
  min-height: 40px;
  padding: 0 0.25rem;
}
.front-theme .cart-btn.mat-mdc-raised-button, .front-theme .head-btn.mat-mdc-raised-button {
  max-height: 100% !important;
  min-width: 0 !important;
}
@media (min-width: 960px) {
  .front-theme .cart-btn, .front-theme .head-btn {
    min-height: 65px;
    margin: 1.3em 0;
    padding: 0 0.5rem;
  }
}
.front-theme .cart-btn .mat-icon, .front-theme .head-btn .mat-icon {
  height: 100%;
  overflow: hidden;
  max-width: 40px;
  width: 40px;
  line-height: 40px;
  margin-left: 0;
  margin-right: 0;
}
@media (min-width: 960px) {
  .front-theme .cart-btn .mat-icon, .front-theme .head-btn .mat-icon {
    font-size: 2.5rem;
    padding: 1rem 0;
    max-width: 85px;
    width: 75%;
  }
}
@media (max-width: 959px) {
  .front-theme .cart-btn .mat-icon {
    padding-left: 9px;
    margin-top: -3px;
  }
}
.front-theme .cart-btn .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  bottom: 0;
  right: 8px;
}
@media (min-width: 960px) {
  .front-theme .cart-btn .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
    bottom: 8px;
    right: 0;
  }
}
@media (min-width: 960px) {
  .front-theme .head-btn .mat-icon {
    margin-bottom: -0.65rem;
    font-size: 1.8rem;
    padding: 0;
  }
}
.front-theme body.front-theme, .front-theme .back-theme app-front-layout > .front-theme {
  /*background-image: url('~assets/images/bg.webp');*/
}
.front-theme .mat-drawer-content::-webkit-scrollbar {
  width: 0;
  height: 10px;
}
.front-theme .tracker-wrapper .brd-8 {
  border-radius: 8px;
}
.front-theme .dark-backdrop {
  background-color: rgba(0, 0, 0, 0.77);
}
.front-theme .image-backdrop {
  background-color: rgba(250, 250, 250, 0.9);
}
.front-theme app-front-layout {
  min-height: 100%;
  display: flex;
  flex: 1;
  align-self: center;
  width: 100%;
}
@media (min-width: 980px) {
  .front-theme .main-content, .front-theme .header-top-wrapper > div, .front-theme .header-top-menu-wrapper > div, .front-theme footer > div {
    margin: 0 auto;
    width: 100%;
  }
}
.front-theme .front-theme .grid {
  display: grid;
  grid-template-columns: minmax(100%, 1fr);
  grid-gap: 0;
}
.front-theme .front-theme .grid-block {
  padding: 0;
}
.front-theme .front-theme .grid-block-wrap {
  height: 100%;
}
.front-theme .front-theme .grid-block {
  position: relative;
}
.front-theme .front-theme .grid-block .image-block {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.front-theme .front-theme .grid-block .image-block img {
  position: absolute;
}
.front-theme .front-theme mat-sidenav-container {
  flex: 1;
}
.front-theme .front-theme mat-sidenav {
  width: 250px;
}
.front-theme .front-theme mat-sidenav a, .front-theme .front-theme mat-sidenav .mat-button {
  color: rgba(0, 0, 0, 0.87);
}
.front-theme .front-theme mat-sidenav a:hover, .front-theme .front-theme mat-sidenav .mat-button:hover {
  color: #2ec610;
}
.front-theme .front-theme .mat-drawer, .front-theme .front-theme .mat-tree {
  background-color: mat-color(#fafafa) !important;
}
.front-theme .front-theme .mat-drawer-side {
  border-right: 1px solid rgba(46, 198, 16, 0.12);
}
.front-theme .front-theme .mat-button-focus-overlay {
  background-color: #2ec610;
}
.front-theme .front-theme .main-content {
  width: 100%;
  max-width: 1140px;
}
.front-theme .front-theme .header-top-wrapper > div, .front-theme .front-theme .header-top-menu-wrapper > div, .front-theme .front-theme footer > div {
  width: 100%;
  max-width: 1140px;
}
.front-theme .front-theme .product-search input {
  caret-color: #fff;
}
.front-theme .front-theme .product-search.mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 4px;
  position: absolute;
  right: 1px;
  text-align: center;
  height: calc(100% - 6px);
}
@media (max-width: 959px) {
  .front-theme .front-theme .product-search.mat-form-field-appearance-outline .mat-form-field-suffix {
    top: 5px;
  }
}
.front-theme .front-theme .product-search.mat-form-field-appearance-outline .mat-form-field-suffix button {
  height: 100%;
  padding: 0;
  min-width: auto;
  border-radius: 0 4px 4px 0;
}
.front-theme .front-theme .product-search.mat-form-field-appearance-outline .mat-form-field-suffix .mat-icon {
  height: 100%;
  width: 40px;
  line-height: 1.4;
}
@media all and (max-width: 959px) {
  .front-theme .front-theme .grid {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .front-theme .front-theme article, .front-theme .front-theme aside {
    min-height: 80vh;
    width: 100%;
  }
}
.front-theme .front-theme aside {
  border-left: 1px solid rgba(46, 198, 16, 0.12);
}
.front-theme .front-theme header {
  /*padding-bottom: min(320px, calc(100% / 3));
  background: url('assets/images/bg-header.webp') no-repeat 50% 110%;-*/
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(0, 0, 0, 0.87);
  /** search */
  /* Change the white to any color */
  /** !search */
}
.front-theme .front-theme header .header-top-wrapper {
  background-color: #151515;
}
.front-theme .front-theme header .header-top-wrapper a {
  color: rgba(0, 0, 0, 0.87);
}
.front-theme .front-theme header .header-top-wrapper a:hover {
  color: #2ec610;
}
.front-theme .front-theme header .header-top-menu-wrapper {
  background-color: #2ec610;
}
.front-theme .front-theme header .mat-list-base {
  padding-top: 0;
}
.front-theme .front-theme header .mat-nav-list .mat-list-item {
  background-color: #2ec610;
  color: rgba(0, 0, 0, 0.87);
}
.front-theme .front-theme header .mat-form-field-outline .mat-form-field-outline-gap,
.front-theme .front-theme header .mat-form-field-outline .mat-form-field-outline-start,
.front-theme .front-theme header .mat-form-field-outline .mat-form-field-outline-end {
  border-color: rgba(0, 0, 0, 0.87);
}
.front-theme .front-theme header .mat-form-field-appearance-outline .mat-form-field-wrapper {
  padding-bottom: 0;
  margin: 0;
}
@media (min-width: 960px) {
  .front-theme .front-theme header .mat-form-field-appearance-outline .mat-form-field-wrapper {
    padding-bottom: 1.3rem;
  }
}
.front-theme .front-theme header .mat-form-field {
  font-size: 0.9rem;
}
.front-theme .front-theme header .mat-form-field input {
  margin-bottom: 0.5rem;
}
.front-theme .front-theme header .mat-form-field .mat-icon {
  color: rgba(0, 0, 0, 0.87);
}
.front-theme .front-theme header .mat-form-field-appearance-outline .mat-form-field-flex {
  padding-right: 1px;
}
.front-theme .front-theme header .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0;
}
.front-theme .front-theme header input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.27) !important;
}
.front-theme .front-theme header input:-webkit-autofill,
.front-theme .front-theme header input:-webkit-autofill:hover,
.front-theme .front-theme header input:-webkit-autofill:focus,
.front-theme .front-theme header input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #151515 inset !important;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.87) !important;
}
@media (min-width: 960px) {
  .front-theme .front-theme article {
    min-height: 300px;
  }
}
.front-theme .front-theme footer {
  background-color: #151515;
  color: rgba(0, 0, 0, 0.87);
  max-width: 100%;
  padding-top: 0.5rem;
}
@media (min-width: 1200px) {
  .front-theme .front-theme footer .page-grid {
    max-width: 1140px;
  }
}
.front-theme .front-theme footer .page-grid {
  row-gap: 0;
}
.front-theme .front-theme footer .page-grid > div {
  background-color: transparent !important;
  padding: 0.5rem;
}
.front-theme .front-theme footer .addBlock {
  color: rgba(0, 0, 0, 0.87);
}
.front-theme .front-theme footer h1, .front-theme .front-theme footer h2, .front-theme .front-theme footer h3, .front-theme .front-theme footer h4, .front-theme .front-theme footer .mat-subheader {
  color: rgba(0, 0, 0, 0.87) !important;
  text-transform: uppercase;
}
.front-theme .front-theme footer a {
  color: rgba(0, 0, 0, 0.87) !important;
}
.front-theme .front-theme footer a :hover {
  color: #2ec610;
}
.front-theme .front-theme footer .mat-nav-list .mat-list-item:hover, .front-theme .front-theme footer .mat-nav-list .mat-list-item:focus {
  background-color: transparent !important;
}
.front-theme .front-theme footer .copy {
  color: rgba(0, 0, 0, 0.38);
  text-align: center;
  font-size: 0.7rem;
}
.front-theme .front-theme .message {
  border: 1px solid rgba(0, 0, 0, 0.38);
  align-items: center;
  justify-content: center;
  padding: 1rem;
  padding-left: calc(0.5rem + 40px);
  position: relative;
}
.front-theme .front-theme .message::before {
  font-size: 1.2rem;
  font-family: "Material Icons", serif;
  content: "info";
  width: 40px;
  position: absolute;
  left: 1rem;
}
.front-theme app-grid, .front-theme app-grid-editor {
  flex: 1;
}
.front-theme app-grid .mat-grid-tile .mat-figure, .front-theme app-grid-editor .mat-grid-tile .mat-figure {
  align-items: flex-start;
}
.front-theme app-grid .page-grid, .front-theme app-grid-editor .page-grid {
  display: grid;
  grid-template-columns: var(--item-min-width);
  grid-gap: 0;
}
.front-theme app-grid .page-grid > div, .front-theme app-grid-editor .page-grid > div {
  position: relative;
}
.front-theme app-grid div[app-text-block], .front-theme app-grid div[app-text-image-block], .front-theme app-grid div[app-company-address-block], .front-theme app-grid div[app-text-block-editor], .front-theme app-grid div[app-text-image-block-editor], .front-theme app-grid-editor div[app-text-block], .front-theme app-grid-editor div[app-text-image-block], .front-theme app-grid-editor div[app-company-address-block], .front-theme app-grid-editor div[app-text-block-editor], .front-theme app-grid-editor div[app-text-image-block-editor] {
  padding: 1em;
}
.front-theme app-grid:not(.first-grid) div[app-image-block], .front-theme app-grid:not(.first-grid) div[app-image-block-editor], .front-theme app-grid-editor:not(.first-grid) div[app-image-block], .front-theme app-grid-editor:not(.first-grid) div[app-image-block-editor] {
  padding: 0 1em;
}
.front-theme app-grid div[app-text-image-block] .image-on-top img, .front-theme app-grid div[app-text-image-block-editor] .image-on-top img, .front-theme app-grid-editor div[app-text-image-block] .image-on-top img, .front-theme app-grid-editor div[app-text-image-block-editor] .image-on-top img {
  margin: 0 0 1rem 0;
}
@media (max-width: 767px) {
  .front-theme app-grid div[app-text-image-block] .image-on-top img, .front-theme app-grid div[app-text-image-block-editor] .image-on-top img, .front-theme app-grid-editor div[app-text-image-block] .image-on-top img, .front-theme app-grid-editor div[app-text-image-block-editor] .image-on-top img {
    width: 100% !important;
  }
}
@media (max-width: 767px) {
  .front-theme app-grid div[app-text-image-block] .image-on-top, .front-theme app-grid div[app-text-image-block-editor] .image-on-top, .front-theme app-grid-editor div[app-text-image-block] .image-on-top, .front-theme app-grid-editor div[app-text-image-block-editor] .image-on-top {
    width: 100% !important;
  }
}
.front-theme app-grid div[app-text-image-block] .image-on-bottom img, .front-theme app-grid div[app-text-image-block-editor] .image-on-bottom img, .front-theme app-grid-editor div[app-text-image-block] .image-on-bottom img, .front-theme app-grid-editor div[app-text-image-block-editor] .image-on-bottom img {
  margin-top: 1rem;
}
@media (min-width: 768px) {
  .front-theme app-grid div[app-text-image-block] .image-on-bottom img, .front-theme app-grid div[app-text-image-block-editor] .image-on-bottom img, .front-theme app-grid-editor div[app-text-image-block] .image-on-bottom img, .front-theme app-grid-editor div[app-text-image-block-editor] .image-on-bottom img {
    width: 100% !important;
  }
}
.front-theme app-grid div[app-text-image-block] .image-on-top.image-on-center img, .front-theme app-grid div[app-text-image-block-editor] .image-on-top.image-on-center img, .front-theme app-grid-editor div[app-text-image-block] .image-on-top.image-on-center img, .front-theme app-grid-editor div[app-text-image-block-editor] .image-on-top.image-on-center img {
  float: none !important;
}
@media (min-width: 768px) {
  .front-theme app-grid div[app-text-image-block] .image-on-top.image-on-center img, .front-theme app-grid div[app-text-image-block-editor] .image-on-top.image-on-center img, .front-theme app-grid-editor div[app-text-image-block] .image-on-top.image-on-center img, .front-theme app-grid-editor div[app-text-image-block-editor] .image-on-top.image-on-center img {
    width: 100% !important;
  }
}
.front-theme app-grid div[app-text-image-block] .image-on-left img, .front-theme app-grid div[app-text-image-block-editor] .image-on-left img, .front-theme app-grid-editor div[app-text-image-block] .image-on-left img, .front-theme app-grid-editor div[app-text-image-block-editor] .image-on-left img {
  float: left;
}
.front-theme app-grid div[app-text-image-block] .image-on-right img, .front-theme app-grid div[app-text-image-block-editor] .image-on-right img, .front-theme app-grid-editor div[app-text-image-block] .image-on-right img, .front-theme app-grid-editor div[app-text-image-block-editor] .image-on-right img {
  float: right;
}
.front-theme app-grid div[app-text-image-block] .image-on-left:not(.image-on-bottom) img, .front-theme app-grid div[app-text-image-block-editor] .image-on-left:not(.image-on-bottom) img, .front-theme app-grid-editor div[app-text-image-block] .image-on-left:not(.image-on-bottom) img, .front-theme app-grid-editor div[app-text-image-block-editor] .image-on-left:not(.image-on-bottom) img {
  margin-right: 1rem;
}
.front-theme app-grid div[app-text-image-block] .image-on-right:not(.image-on-bottom) img, .front-theme app-grid div[app-text-image-block-editor] .image-on-right:not(.image-on-bottom) img, .front-theme app-grid-editor div[app-text-image-block] .image-on-right:not(.image-on-bottom) img, .front-theme app-grid-editor div[app-text-image-block-editor] .image-on-right:not(.image-on-bottom) img {
  margin-left: 1rem;
}
.front-theme app-grid .image-loader, .front-theme app-grid-editor .image-loader {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (min-width: 768px) {
  .front-theme app-grid, .front-theme app-grid-editor {
    width: 90%;
  }
}
@media all and (max-width: 767px) {
  .front-theme app-grid, .front-theme app-grid-editor {
    width: 100%;
  }
  .front-theme app-grid .page-grid, .front-theme app-grid-editor .page-grid {
    display: flex;
    flex-flow: column;
    /*div[app-text-image-block] {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-flow: column;
        justify-content: space-around;

        .iw {
            flex-basis: 100%;
        }

        .iw.image-on-left {
            flex-basis: 30%;

            img {
                max-width: 100%;
                height: auto;
                margin: 0 !important;
            }
        }

        & + .text {
            flex-basis: 70%;
            padding: 0 1rem;;
        }
    }*/
  }
}
.front-theme .mat-mdc-menu-item.active, .front-theme .mat-mdc-list-item.active {
  font-weight: bold;
}
.front-theme app-main-menu {
  width: 100%;
}
.front-theme app-main-menu .main-navigation {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  background-color: #fafafa;
}
.front-theme app-main-menu .main-navigation li {
  flex: 1;
  margin: 0 0.5em;
}
.front-theme app-main-menu .main-navigation .mat-mdc-list-item:first-child .mat-mdc-list-item-content {
  padding-left: 0;
}
.front-theme app-main-menu .main-navigation .mat-mdc-list-item:last-child .mat-mdc-list-item-content {
  padding-right: 0;
}
.front-theme app-main-menu .main-navigation .mat-mdc-list-item {
  width: 100%;
}
.front-theme app-main-menu .main-navigation .mat-mdc-list-item .mat-mdc-list-item-content {
  align-items: center;
  justify-content: center;
}
.front-theme app-main-menu .main-navigation button.mat-mdc-list-item.active {
  background-color: rgba(0, 0, 0, 0.04);
}
.front-theme app-main-menu .main-navigation button.mat-mdc-list-item:hover {
  background-color: rgba(46, 198, 16, 0.04);
}
.front-theme app-main-menu .main-navigation button.mat-mdc-list-item {
  border: none;
  border-radius: 0;
}
.front-theme .app-contact-form {
  padding: 0 6% 6px 6%;
  margin: 1rem;
}
.front-theme .app-contact-form .mat-subheader {
  padding-left: 0;
}
.front-theme .app-contact-form .alert {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em;
  font-size: 1.3em;
  font-weight: bold;
}
.front-theme .app-contact-form .success {
  background-color: rgba(105, 240, 174, 0.6);
}
.front-theme .app-contact-form .error {
  background-color: rgba(255, 82, 82, 0.8);
}
.front-theme footer .app-contact-form {
  /* Change the white to any color */
}
.front-theme footer .app-contact-form .mat-form-field-appearance-fill .mat-form-field-label {
  color: #efefef;
}
.front-theme footer .app-contact-form .mat-form-field-appearance-fill .mat-form-field-underline {
  background-color: #efefef;
}
.front-theme footer .app-contact-form input:-webkit-autofill,
.front-theme footer .app-contact-form input:-webkit-autofill:hover,
.front-theme footer .app-contact-form input:-webkit-autofill:focus,
.front-theme footer .app-contact-form input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #151515 inset !important;
  -webkit-text-fill-color: #efefef !important;
}
.front-theme .front-theme footer .mdc-subheader {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  font-family: Open Sans;
  letter-spacing: 0.0094em;
  color: #efefef;
  text-transform: uppercase;
}
.front-theme .front-theme footer .mdc-list a.mdc-list-item .mdc-list-item__primary-text {
  color: #efefef !important;
}
.front-theme .front-theme footer .mdc-list .mat-list-item .mdc-list-item-content {
  padding-left: 1.5rem !important;
}
.front-theme .front-theme footer .mdc-list .mat-list-item:before {
  font-size: 24px;
  font-family: "Material Icons", serif;
  content: "arrow_right";
  position: absolute;
  top: 11px;
}
.front-theme .front-theme footer .mdc-list .mdc-list-item:hover:before {
  color: #2EC610;
}

/*
* REQUIRED STYLES
*/
/*$adminTheme: mat.define-dark-theme((
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn),
));*/
html {
  font-size: 16px;
  height: 100%;
}

html:not(.landing),
body:not(.landing) {
  width: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
  color: #232323;
  line-height: 1.5;
  min-height: 100%;
}

[tabindex="-1"]:focus {
  outline: none;
}

select,
button,
textarea,
input {
  vertical-align: baseline;
}

div {
  box-sizing: border-box;
}

html[dir=rtl], html[dir=ltr], body[dir=rtl], body[dir=ltr] {
  unicode-bidi: embed;
}

bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

img.max-100 {
  max-width: 100%;
}

p {
  margin: 0 0 16px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.1;
  color: inherit;
}

.h1,
h1 {
  font-size: 2rem;
}

.h2,
h2 {
  font-size: 1.75rem;
}

.h3,
h3 {
  font-size: 1.5rem;
}

.h4,
h4 {
  font-size: 1.25rem;
}

.h5,
h5 {
  font-size: 1rem;
}

.h6,
h6 {
  font-size: 0.875rem;
}

code {
  padding: 8px;
  background: rgba(0, 0, 0, 0.08);
}

sup {
  font-size: 0.6rem !important;
}

.title {
  font-size: 1.25rem;
  font-weight: 100;
  margin: 0.5rem 0;
  color: rgba(35, 35, 35, 0.87);
}

.title > .mat-icon {
  margin-right: 0.5rem;
}

/*---- Common -----*/
.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 15px;
}

.bg-none {
  background: transparent !important;
}

.bg-white {
  background: #ffffff !important;
}

.m-0 {
  margin: 0 !important;
}

.m-05 {
  margin: 0.5rem !important;
}

.m-1 {
  margin: 1rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mt-05 {
  margin-top: 0.5rem !important;
}

@media screen and (max-width: 599px) {
  .xs-mt-05 {
    margin-top: 0.5rem !important;
  }
}
.mt-0 {
  margin-top: 0 !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mr-05 {
  margin-right: 0.5rem !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.ml-05 {
  margin-left: 0.5rem !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mb-05 {
  margin-bottom: 0.5rem !important;
}

.m-333 {
  margin: 0.333333rem !important;
}

.margin-333 {
  margin: 0.333333rem !important;
}

.pt-1 {
  padding-top: 1rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-1 {
  padding-right: 1rem !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-1 {
  padding-left: 1rem !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 1rem !important;
}

.pb-05 {
  padding-bottom: 0.5rem !important;
}

.pt-05 {
  padding-top: 0.5rem !important;
}

.pl-05 {
  padding-left: 0.5rem !important;
}

.pr-05 {
  padding-right: 0.5rem !important;
}

.p-05 {
  padding: 0.5rem !important;
}

.height-100 {
  min-height: 100vh;
}

.fix {
  position: relative;
  overflow: hidden;
}

.fix-elm::after {
  display: table;
  width: 100%;
  content: "";
}

.mat-box-shadow {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.light-gray, .mat-mdc-cell.light-gray {
  background: rgba(0, 0, 0, 0.024);
}

.light-mat-gray {
  background: rgba(0, 0, 0, 0.08);
}

.fz-1 {
  font-size: 1rem !important;
}

.fz-2 {
  font-size: 2rem !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left;
}

.font-light {
  font-weight: 300 !important;
}

.font-normal {
  font-weight: normal !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.text-muted {
  color: rgba(0, 0, 0, 0.54) !important;
}

.text-muted-white {
  color: rgba(255, 255, 255, 0.54) !important;
}

.text-gray {
  color: rgba(0, 0, 0, 0.7) !important;
}

.text-sm {
  font-size: 0.813rem;
}

.list-item-active {
  border-left: 3px solid;
}

.icon-sm {
  font-size: 18px !important;
  line-height: 18px !important;
  height: 18px;
  width: 18px;
}

.icon-xs {
  font-size: 13px !important;
  line-height: 13px;
  height: 13px;
  width: 13px;
}

.mat-card {
  font-size: 0.875rem;
}

.mat-card.default {
  padding: 0;
}

.mat-card.default .mat-card-title {
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 1;
  font-weight: 400;
}

.mat-card.default > :first-child {
  border-radius: 2px 2px 0 0;
}

.mat-card.default .mat-card-subtitle:first-child {
  padding-top: 1rem;
}

.mat-card.default .mat-card-subtitle {
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 1;
}

.mat-card.default .mat-card-content {
  padding: 1rem;
  margin-bottom: 0;
  position: relative;
}

.mat-card.default .mat-card-actions,
.mat-card.default .mat-card-actions:last-child {
  padding: 0.5rem;
  margin: 0;
}

.mat-card.default > :last-child {
  border-radius: 0 0 2px 2px;
}

.mat-card {
  margin: 0.333333rem;
  overflow: hidden;
}

.mat-card .mat-card-title .mat-divider, .mat-divider.full-width {
  margin-left: -24px;
  margin-right: -24px;
}

.mat-card.p-0 .mat-card-title .card-title-text {
  padding: 1rem 1.5rem;
}

.mat-card.p-0 .mat-card-title .card-title-text .card-control {
  height: 24px;
  width: 24px;
  line-height: 24px;
}

.mat-card.p-0 .mat-card-title .card-title-text .mat-card-subtitle {
  margin: 0;
}

.mat-card.p-0 .mat-card-title .mat-divider {
  margin-left: 0;
  margin-right: 0;
  border-top-color: rgba(0, 0, 0, 0.04);
}

.mat-card.p-0 .mat-card-image {
  margin: 0 0 1rem !important;
}

.mat-card.p-0 .mat-card-content {
  padding: 0 1.5rem 1.5rem;
}

.mdc-button,
.mat-mdc-raised-button {
  font-weight: 400 !important;
}

[mat-lg-button] {
  padding: 0 32px !important;
  font-size: 18px;
  line-height: 56px !important;
}

.mat-icon-button[mat-sm-button] {
  height: 24px;
  width: 24px;
  line-height: 24px;
}

.mat-icon-button[mat-xs-button] {
  height: 20px;
  width: 20px;
  line-height: 20px;
}

.mat-icon-button[mat-xs-button] .mat-icon {
  font-size: 16px;
  line-height: 20px !important;
  height: 20px;
  width: 20px;
}

.mat-chip[mat-sm-chip] {
  padding: 4px 6px 4px 6px !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  line-height: 12px !important;
  max-height: 20px;
  box-sizing: border-box;
}

.mat-icon-button.img-button img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.compact-list .mat-list-item {
  line-height: 1.1;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.compact-list .mat-list-item:hover {
  background: rgba(0, 0, 0, 0.08);
  cursor: pointer;
}

.logo-group {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.full-width {
  width: 100% !important;
}

.auto-width {
  width: auto !important;
}

.auto-height {
  height: auto !important;
}

.full-height {
  height: 100%;
}

.mat-form-field-appearance-fill .mat-form-field-subscript-wrapper {
  top: calc(100% - 1.8666667em);
}

.dnd-item {
  cursor: grab;
}

.icon-circle {
  color: rgb(255, 255, 255);
  font-size: 1.2rem;
  text-align: center;
  line-height: 1.6rem;
  border-radius: 50%;
}

.mat-sidenav .mat-list-item:hover {
  background: rgba(0, 0, 0, 0.035) !important;
}

.mat-chip {
  position: relative;
  overflow: hidden;
}

.text-small {
  font-size: 0.813rem;
}

.text-small .mat-icon {
  font-size: 1rem;
  vertical-align: sub;
  margin: 0 2px;
}

.text-bigger {
  font-size: 1.3rem;
}

.mat-card-title {
  font-size: 1rem !important;
}

.icon-chip {
  font-size: 11px !important;
  padding: 4px 8px !important;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.icon-chip .mat-icon {
  font-size: 16px;
  height: 16px;
  width: 16px;
  margin-right: 4px;
}

.mat-chip:not(.mat-basic-chip) {
  display: inline-block;
  padding: 8px 12px 8px 12px;
  border-radius: 24px;
  font-size: 13px;
  line-height: 16px;
}

.chart {
  display: block;
  width: 100%;
}

.form-error-msg {
  margin-top: -1px;
}

.accordion-handle {
  cursor: pointer;
}

.app-accordion {
  max-height: 200px;
  transition: max-height 0.3s ease;
}

.app-accordion.open {
  max-height: 1000px;
}

.app-accordion .accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.app-accordion.open .accordion-content {
  max-height: 800px;
}

.app-accordion.open .hidden-on-open {
  display: none !important;
}

.app-accordion:not(.open) .show-on-open {
  display: none !important;
}

mat-list a[mat-list-item] .mat-list-item-content,
mat-list mat-list-item .mat-list-item-content,
mat-nav-list a[mat-list-item] .mat-list-item-content,
mat-nav-list mat-list-item .mat-list-item-content,
[mat-menu-item],
.mat-option,
body .mat-select-trigger {
  font-size: 0.8rem !important;
}

.mat-ripple {
  position: relative;
}

.fileupload-drop-zone {
  text-align: center;
  border: 1px dashed rgba(0, 0, 0, 0.15);
  padding: 48px;
  height: 120px;
}

.default-table {
  text-align: left;
}

.default-table > thead tr th {
  font-weight: 400;
  padding: 0.9rem 1.2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.default-table tbody tr td {
  padding: 0.9rem 1.2rem;
}

.app-error {
  text-align: center;
  width: 320px;
  max-width: 320px;
  margin: 0 auto;
}

.app-error .error-icon {
  height: 120px;
  width: 120px;
  font-size: 120px;
  float: left;
}

.app-error .error-text {
  float: right;
  width: 200px;
  max-width: 200px;
}

.app-error .error-title {
  line-height: 6rem;
  font-size: 5rem;
  font-weight: 900;
  margin: 0;
}

.app-error .error-subtitle {
  font-size: 1.5rem;
  font-weight: 300;
}

.app-error .error-actions {
  width: 100%;
  overflow: hidden;
  min-height: 54px;
  margin-top: 100px;
}

/*---- Loader ----*/
.app-loader,
.view-loader {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}

.view-loader {
  display: block;
  padding-top: 160px;
  background: rgba(255, 255, 255, 0.3);
  z-index: 9999;
}

.view-loader * {
  margin: auto;
}

.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: auto;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
/*---- Third party adjust -----*/
/*------ Map ------*/
.agm-info-window-content {
  color: rgba(0, 0, 0, 0.87);
}

.ps__rail-y {
  right: 0 !important;
  left: auto !important;
}

[dir=rtl] .pr-1 {
  padding-left: 1rem;
  padding-right: 0 !important;
}
[dir=rtl] .mr-1 {
  margin-right: 0 !important;
  margin-left: 1rem;
}
[dir=rtl] .ps__rail-y {
  right: auto !important;
  left: 0 !important;
}
[dir=rtl] .ps__thumb-y {
  right: auto !important;
  left: 1px !important;
}
[dir=rtl] .ngx-datatable .datatable-footer .datatable-pager .pager {
  float: left;
}
[dir=rtl] .user-card .user-details .mat-icon {
  margin-right: 0;
  margin-left: 0.5rem;
}

@media (max-width: 767px) {
  .mat-card-title .mat-divider {
    margin-left: 0;
    margin-right: 0;
  }
  .accordion-handle {
    flex-direction: column !important;
  }
  .app-error .error-icon {
    height: 100px;
    width: 100px;
    font-size: 100px;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Change the white to any color */
.multiline-mat-option.mat-option {
  white-space: normal;
  line-height: normal;
}

.mat-tree-node .mover {
  user-select: none;
  cursor: move;
}
.mat-tree-node.cdk-drag-preview {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-tree-node.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drop-list-dragging .mat-tree-node:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-animating {
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1);
}

.mat-tab-body-wrapper {
  padding-top: 1em;
}

.mat-mdc-tab-body-content {
  max-height: 80vh;
}

[mat-sort-header].cdk-keyboard-focused .mat-sort-header-container, [mat-sort-header].cdk-program-focused .mat-sort-header-container {
  border-bottom: none !important;
}

.mat-column-actions div button:last-of-type {
  margin-right: 0 !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.mt-25 {
  margin-top: 2.5rem !important;
}

.mb-25 {
  margin-bottom: 2.5rem !important;
}

.fit-content .mat-mdc-select-panel {
  min-width: fit-content;
}

.hidden {
  display: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.odd-row {
  background-color: rgba(0, 0, 0, 0.015);
}

.odd-row:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

.absolute-hint {
  position: absolute;
  top: 1px;
}

a, a:focus, a:hover {
  cursor: pointer;
  text-decoration: none;
  outline: 0;
  color: inherit;
}

button {
  outline: 0;
}

figure {
  margin: 0;
}

strong, b {
  font-weight: 700;
}

.strikethrough {
  text-decoration: line-through;
}

.strong-text {
  font-weight: bold;
}

app-root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex !important;
  flex-direction: row;
}

.flex-col {
  display: flex !important;
  flex-direction: column;
}

.grid {
  display: grid;
}

.flex-wrap {
  flex-wrap: wrap;
}

@media screen and (max-width: 599px) {
  .flex-col-xs {
    flex-direction: column;
  }
}

.gap-1 {
  gap: 1em;
}

.gap-percent-1 {
  gap: 1%;
}

.gap-2 {
  gap: 2em;
}

.gap-25 {
  gap: 2.5em;
}

.space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-stretch {
  align-items: stretch;
}

.self-align-start {
  align-self: flex-start;
}

.self-align-end {
  align-self: flex-end;
}

.flex-1 {
  flex: 1;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-200px {
  width: 200px;
}

.flex-150px {
  flex-basis: 150px;
}

.flex-140px {
  flex-basis: 140px;
}

.flex-100px {
  flex-basis: 100px;
}

.flex-50px {
  flex-basis: 50px;
}

.flex-40px {
  flex-basis: 40px;
}

.flex-0 {
  flex-basis: 0;
}

.flex-100 {
  flex-basis: 100%;
}

.flex-90 {
  flex-basis: 90%;
}

.flex-85 {
  flex-basis: 85%;
}

.flex-80 {
  flex-basis: 80%;
}

.flex-78 {
  flex-basis: 78%;
}

.flex-73 {
  flex-basis: 73%;
}

.flex-70 {
  flex-basis: 70%;
}

.flex-69 {
  flex-basis: 69%;
}

.flex-68 {
  flex-basis: 68%;
}

.flex-66 {
  flex-basis: 66%;
}

.flex-60 {
  flex-basis: 60%;
}

.flex-59 {
  flex-basis: 59%;
}

.flex-58 {
  flex-basis: 58%;
}

.flex-50 {
  flex-basis: 50%;
}

.flex-49 {
  flex-basis: 49%;
}

.flex-48 {
  flex-basis: 48%;
}

.flex-45 {
  flex-basis: 45%;
}

.flex-42 {
  flex-basis: 42%;
}

.flex-40 {
  flex-basis: 40%;
}

.flex-38 {
  flex-basis: 38%;
}

.flex-35 {
  flex-basis: 35%;
}

.flex-33 {
  flex-basis: 33%;
}

.flex-32 {
  flex-basis: 32%;
}

.flex-30 {
  flex-basis: 30%;
}

.flex-28 {
  flex-basis: 28%;
}

.flex-25 {
  flex-basis: 25%;
}

.flex-20 {
  flex-basis: 20%;
}

.flex-19 {
  flex-basis: 19%;
}

.flex-18 {
  flex-basis: 18%;
}

.flex-17 {
  flex-basis: 18%;
}

.flex-15 {
  flex-basis: 15%;
}

.flex-14 {
  flex-basis: 14%;
}

.flex-13 {
  flex-basis: 13%;
}

.flex-10 {
  flex-basis: 10%;
}

.flex-8 {
  flex-basis: 8%;
}

.flex-7 {
  flex-basis: 7%;
}

.flex-6 {
  flex-basis: 6%;
}

.flex-5 {
  flex-basis: 5%;
}

.flex-4 {
  flex-basis: 4%;
}

.flex-2 {
  flex-basis: 2%;
}

.flex-auto {
  flex-basis: auto;
}

.flex-offset-1 {
  offset: 1%;
}

.flex-offset-2 {
  offset: 2%;
}

.flex-offset-10 {
  offset: 10%;
}

@media screen and (max-width: 599px) {
  .flex-xs-100, .flex-lt-sm-100 {
    flex-basis: 100%;
  }
  .flex-xs-50 {
    flex-basis: 50%;
  }
  .hide-xs {
    display: none;
  }
  .flex-lt-sm-col, flex-xs-col {
    flex-direction: column;
  }
  .flex-lt-sm-items-center {
    align-items: center;
  }
  .flex-lt-sm-justify-start {
    justify-content: flex-start;
  }
}
@media screen and (min-width: 600px) {
  .hide-gt-xs {
    display: none;
  }
  .flex-gt-xs-auto {
    flex-basis: auto;
  }
  .flex-gt-xs-33 {
    flex-basis: 33%;
  }
  .flex-gt-xs-50 {
    flex-basis: 50%;
  }
  .flex-gt-xs-70 {
    flex-basis: 70%;
  }
  .flex-gt-xs-grow {
    flex-grow: 1;
  }
  .flex-gt-xs-grow {
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 960px) {
  .hide-gt-sm {
    display: none !important;
  }
  .flex-gt-sm-row {
    flex-direction: row !important;
  }
  .flex-gt-sm-15 {
    flex-basis: 15%;
  }
  .flex-gt-sm-20 {
    flex-basis: 20%;
  }
  .flex-gt-sm-25 {
    flex-basis: 25%;
  }
  .flex-gt-sm-30 {
    flex-basis: 30%;
  }
  .flex-gt-sm-33-33 {
    flex-basis: 33.33%;
  }
  .flex-gt-sm-40 {
    flex-basis: 40%;
  }
  .flex-gt-sm-49 {
    flex-basis: 49%;
  }
  .flex-gt-sm-50 {
    flex-basis: 50%;
  }
  .flex-gt-sm-60 {
    flex-basis: 60%;
  }
}
@media screen and (max-width: 959px) {
  .hide-lt-md, hide-sm {
    display: none !important;
  }
}
@media screen and (min-width: 1198px) {
  .flex-gt-md-row {
    flex-direction: row !important;
  }
  .flex-gt-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-gt-md-100 {
    flex-basis: 100% !important;
  }
  .flex-gt-md-49 {
    flex-basis: 49% !important;
  }
  .flex-gt-md-25 {
    flex-basis: 25% !important;
  }
  .flex-gt-md-10 {
    flex-basis: 10% !important;
  }
}
@media screen and (max-width: 1200px) {
  .flex-lt-lg-auto {
    flex-basis: auto;
  }
}
.hide {
  display: none;
}

/*$adminTheme: mat.define-dark-theme((
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn),
));*/
::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  border-radius: 0;
  border-right: 1px solid rgba(35, 35, 35, 0.4);
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: rgba(35, 35, 35, 0.8);
}

::-webkit-scrollbar:hover {
  width: 20px;
}

.filter-filler {
  height: 50px;
}
@media (max-width: 600px) {
  .filter-filler {
    height: auto;
  }
}

.wcb-table, .wcb-table + .mat-mdc-paginator {
  --mat-form-field-container-height: 36px;
  --mat-form-field-filled-label-display: none;
  --mat-form-field-container-vertical-padding: 6px;
  --mat-form-field-filled-with-label-container-padding-top: 6px;
  --mat-form-field-filled-with-label-container-padding-bottom: 6px;
}
.wcb-table .mat-mdc-icon-button.mat-mdc-button-base, .wcb-table + .mat-mdc-paginator .mat-mdc-icon-button.mat-mdc-button-base {
  padding: 6px;
  width: 36px;
  height: 36px;
}
.wcb-table .mat-mdc-icon-button .mat-mdc-button-touch-target, .wcb-table + .mat-mdc-paginator .mat-mdc-icon-button .mat-mdc-button-touch-target {
  width: 24px;
  height: 24px;
}
.wcb-table .mat-chip, .wcb-table + .mat-mdc-paginator .mat-chip {
  white-space: nowrap;
}
.wcb-table .mat-chip .mat-icon, .wcb-table + .mat-mdc-paginator .mat-chip .mat-icon {
  font-size: 16px;
  height: auto;
  width: auto;
}
.wcb-table .mat-chip.mat-standard-chip .mat-chip-remove, .wcb-table + .mat-mdc-paginator .mat-chip.mat-standard-chip .mat-chip-remove {
  border: none;
  margin-left: 0;
  margin-right: 4px;
}
.wcb-table .reset-btn, .wcb-table + .mat-mdc-paginator .reset-btn {
  margin-top: 0 !important;
  margin-bottom: 2px;
}
.wcb-table .filter-btn, .wcb-table + .mat-mdc-paginator .filter-btn {
  position: absolute;
  top: 1.25rem;
  right: 1rem;
}

.mat-table.mat-table-responsive .mat-header-row {
  display: flex !important;
  flex-direction: column !important;
  height: auto;
  position: sticky;
  top: 0;
  z-index: 2;
  padding-bottom: 1rem;
  margin: 1rem;
  border-radius: 4px;
  min-height: auto;
}
.mat-table.mat-table-responsive .mat-header-row:not(.toggled) {
  display: none !important;
}
.mat-table.mat-table-responsive .mat-header-row .mat-header-cell {
  display: grid !important;
  grid-template-columns: 40% 60%;
  max-width: 100% !important;
  align-items: center !important;
  padding: 0 0.5rem;
  min-height: auto;
}
.mat-table.mat-table-responsive .mat-header-row .mat-header-cell > :first-child {
  grid-column: 1;
}
.mat-table.mat-table-responsive .mat-header-row .mat-header-cell > :last-child {
  grid-column: 2;
}
.mat-table.mat-table-responsive .mat-header-row .mat-header-cell.pt-1:not(:first-child) {
  padding-top: 0.5rem !important;
}
.mat-table.mat-table-responsive .mat-header-row .mat-header-cell > .mat-sort-header-container {
  padding-left: 15px;
}
.mat-table.mat-table-responsive .mat-header-row .mat-header-cell:not([mat-sort-header]), .mat-table.mat-table-responsive .mat-header-row .mat-header-cell.mat-sort-header-disabled, .mat-table.mat-table-responsive .mat-header-row .mat-header-cell.mat-column-actions {
  display: none;
}
.mat-table.mat-table-responsive .mat-header-row .mat-header-cell .mat-form-field-infix {
  border-top: 0;
}
.mat-table.mat-table-responsive .mat-header-row .mat-header-cell .mat-form-field-appearance-fill .mat-form-field-wrapper {
  padding-bottom: 0.25em;
}
.mat-table.mat-table-responsive .mat-header-row .mat-header-cell .mat-form-field-appearance-fill .mat-form-field-underline {
  bottom: 0.25em;
}
.mat-table.mat-table-responsive .mat-row {
  height: auto;
  flex-direction: column !important;
  min-height: auto;
}
.mat-table.mat-table-responsive .mat-row .mat-cell {
  display: grid !important;
  grid-template-columns: 40% 60%;
  align-items: baseline;
  padding: 0.5rem 0.5rem 0 0.5rem;
  word-break: break-word;
  max-width: 100% !important;
  width: 100%;
}
.mat-table.mat-table-responsive .mat-row .mat-cell .mat-chip-list {
  text-align: right;
  display: inline-flex;
}
.mat-table.mat-table-responsive .mat-row .mat-cell > :first-child {
  text-align: left;
  grid-column: 1;
}
.mat-table.mat-table-responsive .mat-row .mat-cell > :last-child {
  text-align: right;
  font-weight: 500;
  grid-column: 2;
}
.mat-table.mat-table-responsive .mat-row .mat-column-actions {
  margin-bottom: 1rem;
  width: 100%;
}
.mat-table.mat-table-responsive .mat-row .mat-column-actions > button {
  margin: 0.5rem 0;
}
.mat-table.mat-table-responsive .mat-row:last-of-type {
  border: none;
}
.mat-table.mat-table-responsive .mat-row:first-of-type {
  border-top: 0.5rem solid #ebebeb;
}
.mat-table.mat-table-responsive .mat-row:not(:last-of-type) {
  border-bottom: 0.5rem solid #ebebeb;
}

.mat-footer-row {
  align-items: flex-start;
  justify-items: center;
  border-top: 0.5rem solid #ebebeb;
  border-bottom: 0.5rem solid #ebebeb;
  padding: 0.5rem 0;
}

.cdk-overlay-pane.modal {
  max-width: 98% !important;
  width: 98% !important;
}

.cat-btn {
  padding: 6px 0.5rem;
  border-radius: 4px;
}

.next-cat {
  padding: 0 0.25rem;
  padding-top: 2px;
  font-size: 1rem;
}

.cat-nav-btn {
  padding: 0 !important;
  line-height: normal !important;
  min-width: auto !important;
  min-height: 22px;
}

.mat-dialog-container app-wcb-table .mat-card, .mat-tab-body app-wcb-table .mat-card {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.product-category-sidenav .mat-drawer-inner-container {
  flex-direction: column;
}

@media (min-width: 601px) and (max-width: 960px) {
  .cdk-overlay-pane.modal {
    max-width: 88% !important;
    width: 88% !important;
  }
  .cat-btn {
    padding: 6px 0.5rem;
    border-radius: 4px;
  }
  .next-cat {
    padding: 0 0.5rem 0 0rem;
    font-size: 1rem;
  }
  .cat-nav-btn {
    padding: 0 !important;
    line-height: normal !important;
    min-width: auto !important;
    min-height: 22px;
  }
}
@media (max-width: 600px) {
  app-wcb-table, app-docs-list .mat-card .mat-table, app-shopping-cart,
  app-order-table {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  app-wcb-table .mat-card, app-docs-list .mat-card .mat-table, app-shopping-cart .mat-card,
  app-order-table .mat-card, app-docs-list .mat-card .mat-table {
    box-shadow: none !important;
  }
}
/*$adminTheme: mat.define-dark-theme((
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn),
));*/
app-web-editor {
  height: 100%;
}
app-web-editor .mat-drawer-content {
  height: auto;
  overflow: hidden;
}

.block-borders {
  position: absolute;
  z-index: 999;
}

.block-borders.vertical {
  height: 100%;
  width: 10px;
}

.block-borders.horizontal {
  height: 10px;
  width: 100%;
}

.dragging-on .block-borders {
  background-color: green;
}

.block-borders.active {
  background-color: orange;
}

.grid-block > .block-actions {
  position: absolute;
  top: 10px;
  right: 10px;
  display: none;
  color: rgba(0, 0, 0, 0.54);
  z-index: 998;
}
.grid-block > .block-actions button:not(:last-child) {
  margin-right: 0.5rem;
}

.mat-mdc-raised-button.visible-on-hover {
  min-width: 30px !important;
  line-height: 20px;
}

.grid-block-remove {
  cursor: pointer !important;
}

.dragger {
  cursor: move !important;
}

.draggable:hover .block-actions {
  display: flex;
}

.draggable:hover .block-actions > .mat-icon {
  display: block;
}

.draggable:hover {
  border-color: rgba(0, 0, 0, 0.54);
}

.draggable {
  box-sizing: border-box;
  border: 1px solid transparent;
  height: 100%;
}

.draggable .visible-on-hover {
  position: absolute;
  display: none;
}

.draggable:hover .visible-on-hover {
  display: block;
}

button.mat-button.add-block {
  width: 100%;
  color: rgba(0, 0, 0, 0.54);
}

/*.ck.ck-content.ck-editor__editable.ck-editor__editable_inline {
    --ck-spacing-standard: 0 !important;
}

.ck.ck-content.ck-editor__editable_inline {
    overflow: unset;
}
*/
.ck.ck-content.ck-editor__editable.ck-editor__editable_inline.ck-focused {
  border-color: #bdbdbd;
}

/*.ck-content hr {
    background-color: mat.get-theme-color($theme, foreground, text) !important;
    height: 1px !important;
}
*/
.ck.ck-editor__editable_inline {
  background-color: var(--mdc-filled-text-field-container-color);
  position: relative;
}
.ck.ck-editor__editable_inline:hover, .ck.ck-editor__editable_inline:focus {
  background-color: #f0f0f0;
}

.ck-powered-by-balloon {
  display: none !important;
}

/*$adminTheme: mat.define-dark-theme((
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn),
));*/
app-auth-layout {
  background-color: #232323;
}

/*---- Session (Sign in, sign up, forgot, lockscreen) -----*/
.page-wrap {
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
}

.session-progress {
  position: relative;
  bottom: -4px;
  z-index: 9999;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.session-form-hold > .mat-card {
  margin: 0;
}