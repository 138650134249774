@use '@angular/material' as mat;
@import '../../../../scss/variables';

.app-contact-form {

    padding: 0 6% 6px 6%;
    margin: 1rem;

    .mat-subheader {
        padding-left: 0;
    }

    .alert {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        padding: 2em;
        font-size: 1.3em;
        font-weight: bold;
    }

    .success {
        background-color: transparentize(mat.get-color-from-palette(mat.$green-palette, A200), 0.4);
    }

    .error {
        background-color: transparentize(mat.get-color-from-palette(mat.$red-palette, A200), 0.2);
    }
}

footer .app-contact-form {

    .mat-form-field-appearance-fill .mat-form-field-label {
        color: $light-primary-text;
    }

    .mat-form-field-appearance-fill .mat-form-field-underline {
        background-color: $light-primary-text;
    }

    /* Change the white to any color */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px map-get($mat-primary, 'darker') inset !important;
        -webkit-text-fill-color: $light-primary-text !important;
    }

}

