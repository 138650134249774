.app-admin-container {
    height: calc(100vh - 64px);

    .sidebar-panel {
        height: 100%;
        z-index: 10001;
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);

        .navigation-hold {
            height: 100%;
            width: 100%;
            background: rgba(255, 255, 255, .95);
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }
    }
}
