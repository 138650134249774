@use '@angular/material' as mat;
@import 'variables';

@include mat.core();
@include mat.all-component-typographies($fontConfig);


// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
// @import url('https://fonts.googleapis.com/css?family=Material+Icons&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');
// @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap');

.material-symbols-rounded {
    font-variation-settings: 'FILL' 0,
    'wght' 300,
    'GRAD' 50,
    'opsz' 20
}

// Theme Init
@include mat.all-component-themes($theme);

.theme-alternate {
    @include mat.all-component-colors($altTheme);
}

// You can add global styles to this file, and also import other style files
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent;
}

// fix angular material (https://github.com/angular/components/issues/4609)
// actions div not sticking in bottom
.dialog-container {
    height: 100%;
    display: flex;
    flex-direction: column;

    .mat-mdc-dialog-content {
        flex-grow: 1;
        max-height: unset;
    }
}

.mat-mdc-dialog-actions {
    padding: 1rem !important;
}

.mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
    background: map-get($mat-accent, main) !important;
}

.mat-mdc-select .mat-mdc-select-trigger .mat-icon {
    font-size: 20px;
    height: 18px;
}


.mat-mdc-snack-bar-container .mdc-snackbar__surface,
.mat-mdc-snack-bar-container .mdc-snackbar__label{
    border-radius: 6px;
}

.mat-mdc-snack-bar-container.error .mdc-snackbar__surface,
.mat-mdc-snack-bar-container.error .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled),
.mat-mdc-snack-bar-container.error .mdc-snackbar__label {
    color: map-get($mat-error, 'main');
    background-color: map-get($mat-error, 'lighter');
}

.mat-mdc-snack-bar-container.warn .mdc-snackbar__surface,
.mat-mdc-snack-bar-container.warn .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled),
.mat-mdc-snack-bar-container.warn .mdc-snackbar__label {
    color: map-get($mat-info, 'main');
    background-color: map-get($mat-info, 'lighter');
}

.mat-mdc-snack-bar-container.success .mdc-snackbar__surface,
.mat-mdc-snack-bar-container.success .mat-mdc-button,
.mat-mdc-snack-bar-container.success .mdc-snackbar__label{
    color: map-get($mat-success, 'main');
    background-color: map-get($mat-success, 'lighter');
}
