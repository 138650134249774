@use '@angular/material' as mat;
@import '../variables';

app-auth-layout {
    background-color: map-get($mat-primary, main);
}

/*---- Session (Sign in, sign up, forgot, lockscreen) -----*/
.page-wrap {
    display: flex;
    align-items: center;
    padding: 40px 1rem;
    height: 100%;
}

.session-progress {
    position: relative;
    bottom: -4px;
    z-index: 9999;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.session-form-hold {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}

.session-form-hold > .mat-card {
    margin: 0;
}

