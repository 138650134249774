@use '@angular/material' as mat;

// include the custom theme object into the angular material theme

body.front-theme {
    //background-image: url("assets/images/bg_body.svg");
    background-color: mat.get-theme-color($theme, background, background);
}

.front-theme {

    /*.material-icons {
        font-family: 'Material Icons Outlined', 'Material Icons', serif;
    }*/

    color: mat.get-theme-color($theme, foreground, text);
    background-size: 400px auto;
    background-repeat: repeat;

    h1 {
        text-align: left;
        width: 100%;
    }

    h3 {
        margin-top: 2rem;
    }

    a {
        color: mat.get-theme-color($theme, accent, default);
    }

    a:hover {
        color: darken(mat.get-theme-color($theme, accent, default), 10);
    }

    .mat-drawer-container {
        background-color: transparent;

        .mat-sidenav a {
            color: mat.get-theme-color($theme, accent, default) !important;
        }

    }

    .mat-drawer-inner-container {
        overflow-x: hidden;
    }

    .mat-button {
        border-radius: 0
    }

    .mat-warn:not([color="warn"]) {
        color: mat.get-theme-color($theme, warn, default);
    }

    .mat-toolbar-single-row {
        padding: 0;
    }

    .mat-bg-accent {
        color: $light-text;
        background-color: mat.get-theme-color($theme, accent, default);
    }

    .mat-bg-primary {
        color: $light-text;
        background-color: mat.get-theme-color($theme, primary, default);
    }

    .text-accent {
        color: mat.get-theme-color($theme, accent, default);
    }

    .cart-btn, .head-btn {
        color: mat.get-theme-color($theme, foreground, text);
        min-height: 40px;
        padding: 0 0.25rem;

        &.mat-mdc-raised-button {
            max-height: 100% !important;
            min-width: 0 !important;
        }

        @media(min-width: 960px) {
            min-height: 65px;
            margin: 1.3em 0;
            padding: 0 0.5rem;
        }

    }

    .cart-btn .mat-icon, .head-btn .mat-icon {
        //font-size: 1rem;
        height: 100%;
        overflow: hidden;
        max-width: 40px;
        width: 40px;
        line-height: 40px;
        margin-left: 0;
        margin-right: 0;

        @media(min-width: 960px) {
            font-size: 2.5rem;
            padding: 1rem 0;
            max-width: 85px;
            width: 75%;
        }
    }

    .cart-btn .mat-icon {
        @media(max-width: 959px) {
            padding-left: 9px;
            margin-top: -3px;
        }
    }

    .cart-btn .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
        bottom: 0;
        right: 8px;

        @media(min-width: 960px) {
            bottom: 8px;
            right: 0;
        }
    }

    .head-btn .mat-icon {
        @media(min-width: 960px) {
            margin-bottom: -0.65rem;
            font-size: 1.8rem;
            padding: 0;
        }


    }

    body.front-theme, .back-theme app-front-layout > .front-theme {
        /*background-image: url('~assets/images/bg.webp');*/
    }

    .mat-drawer-content::-webkit-scrollbar {
        width: 0;
        height: 10px;
    }

    .tracker-wrapper {
        .brd-8 {
            border-radius: 8px;
        }
    }

    .dark-backdrop {
        background-color: transparentize(mat.get-theme-color($theme, foreground, text), 0.1); //$dark-primary-text
    }

    .image-backdrop {
        background-color: transparentize(mat.get-theme-color($theme, background, background), 0.1); //$dark-primary-text;
    }

    @import "../views/front/web";
    @import '../views/front/blocks/block';
    @import '../views/front/blocks/main-menu';
    @import '../views/front/blocks/contact';
    @import '../views/front/blocks/menu-item-list';

}
