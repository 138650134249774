/*
* REQUIRED STYLES
*/
@import "main/scaffolding";
@import "main/typography";
@import "main/layout";
@import "main/scrollbar";

//wcb-table
@import "../../../app/shared/services/data-source/wcb.table.component";

//ckeditor
@import "main/wysiwyg-editting";
