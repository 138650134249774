@use "@angular/material" as mat;
@import '../../../variables.scss';

.mat-mdc-menu-item.active, .mat-mdc-list-item.active {
    font-weight: bold;
}

app-main-menu {

    width: 100%;

    .main-navigation {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        background-color: mat.get-theme-color($theme, background, background);

        li {
            flex: 1;
            margin: 0 0.5em;
        }

        .mat-mdc-list-item:first-child .mat-mdc-list-item-content {
            padding-left: 0;
        }

        .mat-mdc-list-item:last-child .mat-mdc-list-item-content {
            padding-right: 0;
        }

        .mat-mdc-list-item {
            width: 100%;

            .mat-mdc-list-item-content {
                align-items: center;
                justify-content: center;
            }

        }

        button.mat-mdc-list-item.active {
            background-color: mat.get-theme-color($theme, background, hover);
        }

        button.mat-mdc-list-item:hover {
            background-color: rgba(mat.get-theme-color($theme, accent, default), 0.04);
        }

        button.mat-mdc-list-item {
            border: none;
            border-radius: 0;
        }

    }
}
