@use '@angular/material' as mat;
@import '../variables.scss';
@import "../views/admin/_layout";

// include the custom theme object into the angular material theme
.back-theme {

    // @include mat.all-component-colors($theme);

    @include mat.form-field-density(-1);

    .mat-bg-primary,
    .top-bar:not(.top-bar-white),
    .chats-wrap .conversations-hold .single-conversation.me .conversation-msg,
    .ngx-datatable .datatable-footer .datatable-pager .pager .pages.active a,
    .fileupload-drop-zone.dz-file-over,
    .cal-open-day-events {
        background: mat.get-theme-color($theme, primary, default) !important;
        color: #ffffff !important;
    }

    .mat-color-primary,
    .list-item-active,
    .sidenav-hold .sidenav li.active > a .mat-icon,
    .sidenav-hold .sidenav li.active > div > a .mat-icon,
    .sidenav-hold .sidenav li.active > div > a > span:not(.menuitem-badge),
    .sidenav-hold .sidenav li.active > a > span {
        color: mat.get-theme-color($theme, primary, default) !important;
    }

    .topnav ul.menu > li > div.active > a,
    .topnav ul.menu > li > div.active > div,
    .sidebar-panel .sidebar-list-item.active > .mat-list-item-content > .sub-menu,
    .sidenav-hold .sidenav li.active > div > a .mat-icon,
    .list-item-active {
        border-color: mat.get-theme-color($theme, primary, default) !important;
    }

    .mat-mdc-raised-button > .mat-icon {
        /*margin-left: 0;
        margin-right: 0;*/
    }

    .sidebar-panel {
        background: url('/assets/images/sidebar-bg.jpg') no-repeat;
        background-size: cover;
    }

    .mat-cell .mat-form-field-infix, .mat-header-cell .mat-form-field-infix {
        width: 100%;
    }

    .mat-cell.no-height {
        min-height: 0;
    }

    mat-header-row {
        align-items: flex-start;
    }

    .mat-header-cell {
        flex-direction: column;
        align-items: start;
        padding: 0.8rem;
    }

    .mat-header-cell.text-right {
        justify-content: flex-start;
    }

    .mat-form-field-suffix {
        padding-left: 0.5rem;
    }

    mat-row:hover {
        background: rgba(0, 0, 0, 0.015);
    }

    mat-row::after {
        min-height: 0;
    }

    .mdc-dialog__title {
        padding-top: 1rem;
    }

    /*.mat-dialog-container {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      padding-left: 0;
      padding-right: 0;
    }

    .mat-dialog-container > * {
      flex-direction: column;
      height: 100%;
    }

    mat-dialog-actions.higher-actions {
      min-height: 80px;
      padding: 10px 0 0 0;
    }

    div[mat-dialog-content] {
      // max-height: 80vh;
      margin: 0;
    }

    div[mat-dialog-title], h1[mat-dialog-title], h3[mat-dialog-title] {
      font-weight: 200;
      font-size: 1.5em;
      padding: 0 1em 1em 1em;
      border-bottom: 1px solid mat.$black-12-opacity;
    }

    div[mat-dialog-actions] {
      padding: 1em;
      border-top: 1px solid mat.$black-12-opacity;

      .mat-button {
        margin-right: 0.5em
      }
    }
  */
    .mat-expansion-panel-header-description, .mat-expansion-indicator::after {
        color: mat.get-theme-color($theme, foreground, text);
    }

    .table-danger, .table-alerted {
        background-color: transparentize(map-get($mat-warn, main), 0.75);
        color: mat.get-theme-color($theme, warn, default) !important;

        .mat-cell, .text-muted {
            color: mat.get-theme-color($theme, warn, default) !important;
        }
    }

    .table-warning {
        background-color: transparentize(mat.get-theme-color($theme, warn, default), 0.85);
    }

    .table-pre-warning {
        background-color: transparentize(mat.get-theme-color($theme, warn, default), 0.90);
    }

    .alert-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: .5rem;
        border: 1px solid #1166cc;
        background-color: transparentize(#1166cc, 0.90);
        color: #1166cc;
        margin-top: 0.5em;
        border-radius: 4px;
    }

    .table-success {
        background-color: rgba(0, 255, 0, 0.1);
    }

    .mat-input-element:disabled, .back-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
        color: mat.get-theme-color($theme, foreground, secondary-text);
    }

    mat-chip-list.in-table .mat-chip-list-wrapper {
        margin: 0;
    }

    .left-border {
        border-left: 1px solid rgba(0, 0, 0, 0.09);
    }

    .simple-button {
        min-width: auto;
        line-height: 1rem;
        border-bottom: 1px dashed #999;
    }

    .version {
        text-align: center;
        color: mat.get-theme-color($theme, foreground, secondary-text);
        max-height: 36px;
        overflow: hidden;
    }

    .text-bold {
        font-weight: bolder;
    }

    .mat-tab-body-wrapper {
        height: 100%;
    }

    .cdk-tree-node:not(:last-child) li {
        border-bottom: 1px #ededed solid;
    }

    .mat-drawer-container, .mat-toolbar, .mat-card, .mat-table, .mat-paginator {
        background-color: #fff;
    }

    .mat-card .mat-divider-horizontal {
        position: static;
    }

    .mdc-data-table__header-cell:empty {
        padding: 0 0.5rem;
    }

    .mdc-data-table__cell, .mdc-data-table__header-cell:has(*) {
        padding: 0;
        align-items: center;

        & > * {
            width: -webkit-fill-available;
            padding: 0 0.5rem;
        }

        .mat-mdc-form-field-hint-wrapper {
            padding: 0 0;
            align-items: flex-end;
        }
    }

    .mat-mdc-form-field-infix {
        width: auto;
    }

    .suffix-bottom-line .mat-mdc-form-field-icon-suffix {
        align-self: auto;
    }

    .mat-mdc-form-field .mdc-button, .table-actions {
        min-width: auto;

        .mat-icon {
            margin: 0 4px;
        }

        .mdc-button {
            min-width: auto;
        }
    }

    .amount-select {
        padding-left: 0px;

        .mdc-text-field {
            padding: 0 4px;
        }
    }

    .currency-toggle {
        cursor: pointer;
        border-bottom: 1px dashed gray
    }

    .icon-select-trigger .mat-mdc-form-field-infix {
        padding-bottom: 0 !important;

        .mat-mdc-select-arrow {
            display: none;
        }

    }

    .disabled-card {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        content: '';
        top: 0;
        left: 0;
        z-index: 2;
        opacity: 0.3;
        background-color: grey;
        border-radius: 3px;

        .mat-icon {
            font-size: 3rem;
            width: auto;
            height: auto;
        }
    }

    .add-item-card{
        background-color: mat.get-theme-color($theme, accent, lighter);
    }

}

