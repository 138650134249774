a, a:focus, a:hover {
    cursor: pointer;
    text-decoration: none;
    outline: 0;
    color: inherit;
}

button {
    outline: 0;
}

figure {
    margin: 0;
}

strong, b {
    font-weight: 700;
}

.strikethrough {
    text-decoration: line-through;
}

.strong-text {
    font-weight: bold;
}
