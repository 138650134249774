@use '@angular/material' as mat;

.filter-filler {
    height: 50px;

    @media (max-width: 600px) {
        height: auto;
    }
}

.wcb-table, .wcb-table + .mat-mdc-paginator {

    @include mat.form-field-density(-5);

    .mat-mdc-icon-button.mat-mdc-button-base {
        padding: 6px;
        width: 36px;
        height: 36px;
    }

    .mat-mdc-icon-button .mat-mdc-button-touch-target {
        width: 24px;
        height: 24px;
    }

    .mat-chip {
        white-space: nowrap;
    }

    .mat-chip .mat-icon {
        font-size: 16px;
        height: auto;
        width: auto;
    }

    .mat-chip.mat-standard-chip .mat-chip-remove {
        border: none;
        margin-left: 0;
        margin-right: 4px;
    }

    .reset-btn {
        margin-top: 0 !important;
        margin-bottom: 2px;
    }

    .filter-btn {
        position: absolute;
        top: 1.25rem;
        right: 1rem;
    }

}

.mat-table.mat-table-responsive {
    .mat-header-row {
        display: flex !important;
        flex-direction: column !important;
        height: auto;
        position: sticky;
        top: 0;
        z-index: 2;
        padding-bottom: 1rem;
        margin: 1rem;
        border-radius: 4px;
        min-height: auto;

        &:not(.toggled) {
            display: none !important;
        }

        .mat-header-cell {
            display: grid !important;
            grid-template-columns: 40% 60%;
            max-width: 100% !important;
            align-items: center !important;
            padding: 0 0.5rem;
            //justify-content: space-between !important;
            min-height: auto;

            & > :first-child {
                grid-column: 1;
            }

            & > :last-child {
                grid-column: 2;
            }

            &.pt-1:not(:first-child) {
                padding-top: 0.5rem !important;
            }

            & > .mat-sort-header-container {
                padding-left: 15px; // padding is used to center the header table cells, due to the width of the sort icon
            }

            &:not([mat-sort-header]),
            &.mat-sort-header-disabled,
            &.mat-column-actions {
                display: none;
            }

            .mat-form-field-infix {
                border-top: 0;
            }

            .mat-form-field-appearance-fill .mat-form-field-wrapper {
                padding-bottom: 0.25em;
            }

            .mat-form-field-appearance-fill .mat-form-field-underline {
                bottom: 0.25em;
            }

        }
    }

    .mat-row {
        height: auto;
        flex-direction: column !important;
        min-height: auto;

        .mat-cell {
            display: grid !important;
            grid-template-columns: 40% 60%;
            align-items: baseline;
            padding: 0.5rem 0.5rem 0 0.5rem;
            word-break: break-word;
            max-width: 100% !important;
            width: 100%;

            .mat-chip-list {
                text-align: right;
                display: inline-flex;
            }

            & > :first-child {
                text-align: left;
                grid-column: 1;
            }

            & > :last-child {
                text-align: right;
                font-weight: 500;
                grid-column: 2;
            }
        }

        .mat-column-actions {
            margin-bottom: 1rem;
            width: 100%;

            & > button {
                margin: 0.5rem 0;
            }
        }

        &:last-of-type {
            border: none;
        }

        &:first-of-type {
            border-top: 0.5rem solid #ebebeb;
        }

        &:not(:last-of-type) {
            border-bottom: 0.5rem solid #ebebeb;
        }
    }
}

.mat-footer-row {
    align-items: flex-start;
    justify-items: center;
    border-top: 0.5rem solid #ebebeb;
    border-bottom: 0.5rem solid #ebebeb;
    padding: 0.5rem 0;
}

.cdk-overlay-pane.modal {
    max-width: 98% !important;
    width: 98% !important;
}

.cat-btn {
    padding: 6px 0.5rem;
    border-radius: 4px;
}

.next-cat {
    padding: 0 0.25rem;
    padding-top: 2px;
    font-size: 1rem;
}

.cat-nav-btn {
    padding: 0 !important;
    line-height: normal !important;
    min-width: auto !important;
    min-height: 22px;
}

.mat-dialog-container, .mat-tab-body {
    app-wcb-table .mat-card {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

.product-category-sidenav .mat-drawer-inner-container {
    flex-direction: column;
}


@media(min-width: 601px) and (max-width: 960px) {
    .cdk-overlay-pane.modal {
        max-width: 88% !important;
        width: 88% !important;
    }

    .cat-btn {
        padding: 6px 0.5rem;
        border-radius: 4px;
    }

    .next-cat {
        padding: 0 0.5rem 0 0rem;
        font-size: 1rem;
    }

    .cat-nav-btn {
        padding: 0 !important;
        line-height: normal !important;
        min-width: auto !important;
        min-height: 22px;
    }
}

@media (max-width: 600px) {

    app-wcb-table, app-docs-list .mat-card .mat-table, app-shopping-cart,
    app-order-table {
        margin-right: -1rem !important;
        margin-left: -1rem !important;
    }

    app-wcb-table .mat-card, app-docs-list .mat-card .mat-table, app-shopping-cart .mat-card,
    app-order-table .mat-card, app-docs-list .mat-card .mat-table {
        box-shadow: none !important;
    }
}

