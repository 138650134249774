@use '@angular/material' as mat;
@import '../../variables.scss';

app-front-layout {
    min-height: 100%;
    display: flex;
    flex: 1;
    align-self: center;
    width: 100%;
}

@media (min-width: 980px) {
    .main-content, .header-top-wrapper > div, .header-top-menu-wrapper > div, footer > div {
        margin: 0 auto;
        width: 100%;
    }
}

.front-theme {

    .grid {
        display: grid;
        grid-template-columns: minmax(100%, 1fr); // minmax(200px, 1fr);
        grid-gap: 0;
        //min-width: 100%;
    }

    .grid-block {
        padding: 0
    }

    .grid-block-wrap {
        height: 100%;
    }

    .grid-block {
        position: relative
    }

    .grid-block .image-block {
        position: relative;
        overflow: hidden;
        width: 100%;
    }

    .grid-block .image-block img {
        position: absolute
    }

    mat-sidenav-container {
        flex: 1
    }

    mat-sidenav {
        width: 250px;

        a, .mat-button {
            color: mat.get-theme-color($theme, foreground, text);

            &:hover {
                color: mat.get-theme-color($theme, accent, default)
            }
        }

    }

    .mat-drawer, .mat-tree {
        background-color: mat-color(mat.get-theme-color($theme, background, background)) !important;
    }

    .mat-drawer-side {
        border-right: 1px solid rgba(mat.get-theme-color($theme, accent, default), 0.12);
    }

    .mat-button-focus-overlay {
        background-color: mat.get-theme-color($theme, accent, default);
    }

    .main-content {
        width: 100%;
        max-width: 1140px;
    }

    .header-top-wrapper > div, .header-top-menu-wrapper > div, footer > div {
        width: 100%;
        max-width: 1140px;
    }

    .product-search {
        input {
            caret-color: #fff;
        }

        &.mat-form-field-appearance-outline .mat-form-field-suffix {
            top: 4px;
            @media(max-width: 959px) {
                top: 5px;
            }
            position: absolute;
            right: 1px;
            text-align: center;
            height: calc(100% - 6px);

            button {
                height: 100%;
                padding: 0;
                min-width: auto;
                border-radius: 0 4px 4px 0;
            }

            .mat-icon {
                height: 100%;
                width: 40px;
                line-height: 1.40;
            }
        }

    }

    @media all and (max-width: 959px) {
        .grid {
            display: flex;
            flex-flow: column;
            align-items: flex-start;
            justify-content: flex-start;
        }

        article, aside {
            min-height: 80vh;
            width: 100%;
        }
    }

    aside {
        border-left: 1px solid rgba(mat.get-theme-color($theme, accent, default), 0.12);
    }

    header {
        /*padding-bottom: min(320px, calc(100% / 3));
        background: url('assets/images/bg-header.webp') no-repeat 50% 110%;-*/
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        color: mat.get-theme-color($theme, foreground, text);
        background-color: mat.get-theme-color($theme, foreground, text);

        .header-top-wrapper {
            background-color: mat.get-theme-color($theme, primary, darker);
            @media(max-width: 959px) {
                // opacity: 0.9;
            }

            a {
                color: mat.get-theme-color($theme, foreground, text);

                &:hover {
                    color: mat.get-theme-color($theme, accent, default);
                }
            }
        }

        .header-top-menu-wrapper {
            background-color: mat.get-theme-color($theme, accent, default);
        }

        .mat-list-base {
            padding-top: 0;
        }

        .mat-nav-list .mat-list-item {
            background-color: mat.get-theme-color($theme, accent, default);
            color: mat.get-theme-color($theme, foreground, text);
        }


        /** search */

        .mat-form-field-outline .mat-form-field-outline-gap,
        .mat-form-field-outline .mat-form-field-outline-start,
        .mat-form-field-outline .mat-form-field-outline-end {
            border-color: mat.get-theme-color($theme, foreground, text);
        }

        .mat-form-field-appearance-outline .mat-form-field-wrapper {
            padding-bottom: 0;
            margin: 0;
            @media(min-width: 960px) {
                padding-bottom: 1.3rem;
            }
        }

        .mat-form-field {
            font-size: .9rem;

            input {
                margin-bottom: 0.5rem;
            }

            .mat-icon {
                color: mat.get-theme-color($theme, foreground, text);
            }
        }

        .mat-form-field-appearance-outline .mat-form-field-flex {
            padding-right: 1px;
        }

        .mat-form-field-appearance-outline .mat-form-field-infix {
            padding: 0;
        }

        input::-webkit-input-placeholder {
            color: transparentize(mat.get-theme-color($theme, foreground, text), 0.6) !important;
        }

        /* Change the white to any color */
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px mat.get-theme-color($theme, primary, darker) inset !important;
            -webkit-text-fill-color: mat.get-theme-color($theme, foreground, text) !important;
        }

        /** !search */
    }

    article {
        @media (min-width: 960px) {
            min-height: 300px;
        }
    }

    footer {
        background-color: mat.get-theme-color($theme, primary, darker);
        color: mat.get-theme-color($theme, foreground, text);
        max-width: 100%;
        padding-top: .5rem;

        @media (min-width: 1200px) {
            .page-grid {
                max-width: 1140px;
            }
        }

        .page-grid {
            row-gap: 0;
        }

        .page-grid > div {
            background-color: transparent !important;
            padding: 0.5rem;
        }

        .addBlock {
            color: mat.get-theme-color($theme, foreground, text)
        }

        h1, h2, h3, h4, .mat-subheader {
            color: mat.get-theme-color($theme, foreground, text) !important;
            text-transform: uppercase;
        }

        a {
            color: mat.get-theme-color($theme, foreground, text) !important; //map-get($mat-accent, 'lighter');
            :hover {
                color: mat.get-theme-color($theme, accent, default)
            }
        }

        .mat-nav-list .mat-list-item:hover, .mat-nav-list .mat-list-item:focus {
            background-color: transparent !important;
        }

        .copy {
            color: mat.get-theme-color($theme, foreground, disabled-text);
            text-align: center;
            font-size: 0.7rem;
        }
    }

    .message {
        border: 1px solid mat.get-theme-color($theme, foreground, disabled-text);
        align-items: center;
        justify-content: center;
        padding: 1rem;
        padding-left: calc(0.5rem + 40px);
        position: relative;

        &::before {
            font-size: 1.2rem;
            font-family: 'Material Icons', serif;
            content: 'info';
            width: 40px;
            position: absolute;
            left: 1rem;
        }
    }

}
