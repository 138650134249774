@use '@angular/material' as mat;
@import '../variables';

html {
    font-size: 16px;
    height: 100%;
}

html:not(.landing),
body:not(.landing) {
    width: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    margin: 0;
    padding: 0;
    color: $dark-text;
    line-height: 1.5;
    min-height: 100%;
}

[tabindex='-1']:focus {
    outline: none;
}

select,
button,
textarea,
input {
    vertical-align: baseline;
}

div {
    box-sizing: border-box;
}

html, body {
    &[dir=rtl], &[dir=ltr] {
        unicode-bidi: embed
    }
}

bdo[dir=rtl] {
    direction: rtl;
    unicode-bidi: bidi-override;
}

bdo[dir=ltr] {
    direction: ltr;
    unicode-bidi: bidi-override;
}

img.max-100 {
    max-width: 100%;
}

p {
    margin: 0 0 16px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: .5rem;
    font-weight: 400;
    line-height: 1.1;
    color: inherit;
}

.h1,
h1 {
    font-size: 2rem;
}

.h2,
h2 {
    font-size: 1.75rem;
}

.h3,
h3 {
    font-size: 1.5rem;
}

.h4,
h4 {
    font-size: 1.25rem;
}

.h5,
h5 {
    font-size: 1rem;
}

.h6,
h6 {
    font-size: .875rem;
}

code {
    padding: 8px;
    background: rgba(0, 0, 0, .08);
}

sup {
    font-size: 0.6rem !important;
}

.title {
    font-size: 1.25rem;
    font-weight: 100;
    margin: 0.5rem 0;
    color: $dark-primary-text;
}

.title > .mat-icon {
    margin-right: .5rem;
}

/*---- Common -----*/
.container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 15px;
}

.bg-none {
    background: transparent !important;
}

.bg-white {
    background: #ffffff !important;
}

.m-0 {
    margin: 0 !important;
}

.m-05 {
    margin: 0.5rem !important;
}

.m-1 {
    margin: 1rem !important;
}

.mt-1 {
    margin-top: 1rem !important;
}

.mt-05 {
    margin-top: .5rem !important;
}

@media screen and (max-width: 599px) {
    .xs-mt-05 {
        margin-top: .5rem !important;
    }
}

.mt-0 {
    margin-top: 0 !important;
}

.mr-1 {
    margin-right: 1rem !important;
}

.mr-05 {
    margin-right: .5rem !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.mb-1 {
    margin-bottom: 1rem !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.ml-1 {
    margin-left: 1rem !important;
}

.ml-05 {
    margin-left: .5rem !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.mb-05 {
    margin-bottom: .5rem !important;
}

.m-333 {
    margin: .333333rem !important;
}

.margin-333 {
    margin: .333333rem !important;
}

.pt-1 {
    padding-top: 1rem !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pr-1 {
    padding-right: 1rem !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pb-1 {
    padding-bottom: 1rem !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pl-1 {
    padding-left: 1rem !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.p-0 {
    padding: 0 !important;
}

.p-1 {
    padding: 1rem !important;
}

.pb-05 {
    padding-bottom: .5rem !important;
}

.pt-05 {
    padding-top: .5rem !important;
}

.pl-05 {
    padding-left: .5rem !important;
}

.pr-05 {
    padding-right: .5rem !important;
}

.p-05 {
    padding: .5rem !important;
}

.height-100 {
    min-height: 100vh;
}

.fix {
    position: relative;
    overflow: hidden;
}

.fix-elm::after {
    display: table;
    width: 100%;
    content: "";
}

.mat-box-shadow {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
}

.light-gray, .mat-mdc-cell.light-gray {
    background: rgba(0, 0, 0, .024);
}

.light-mat-gray {
    background: rgba(0, 0, 0, .08);
}

.fz-1 {
    font-size: 1rem !important;
}

.fz-2 {
    font-size: 2rem !important;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left;
}

.font-light {
    font-weight: 300 !important;
}

.font-normal {
    font-weight: normal !important;
}

.fw-300 {
    font-weight: 300 !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.fw-800 {
    font-weight: 800 !important;
}

.fw-900 {
    font-weight: 900 !important;
}

.text-muted {
    color: rgba(0, 0, 0, .54) !important;
}

.text-muted-white {
    color: rgba(255, 255, 255, .54) !important;
}

.text-gray {
    color: rgba(0, 0, 0, .7) !important;
}

.text-sm {
    font-size: .813rem;
}

.list-item-active {
    border-left: 3px solid;
}

.icon-sm {
    font-size: 18px !important;
    line-height: 18px !important;
    height: 18px;
    width: 18px;
}

.icon-xs {
    font-size: 13px !important;
    line-height: 13px;
    height: 13px;
    width: 13px;
}

.mat-card {
    font-size: .875rem;
}

.mat-card.default {
    padding: 0;
}

.mat-card.default .mat-card-title {
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    line-height: 1;
    font-weight: 400;
}

.mat-card.default > :first-child {
    border-radius: 2px 2px 0 0;
}

.mat-card.default .mat-card-subtitle:first-child {
    padding-top: 1rem;
}

.mat-card.default .mat-card-subtitle {
    padding-left: 1rem;
    padding-right: 1rem;
    line-height: 1;
}

.mat-card.default .mat-card-content {
    padding: 1rem;
    margin-bottom: 0;
    position: relative;
}

.mat-card.default .mat-card-actions,
.mat-card.default .mat-card-actions:last-child {
    padding: .5rem;
    margin: 0;
}

.mat-card.default > :last-child {
    border-radius: 0 0 2px 2px;
}

.mat-card {
    margin: .333333rem;
    overflow: hidden;
}

.mat-card .mat-card-title .mat-divider, .mat-divider.full-width {
    margin-left: -24px;
    margin-right: -24px;
}

.mat-card.p-0 .mat-card-title .card-title-text {
    padding: 1rem 1.5rem;
}

.mat-card.p-0 .mat-card-title .card-title-text .card-control {
    height: 24px;
    width: 24px;
    line-height: 24px;
}

.mat-card.p-0 .mat-card-title .card-title-text .mat-card-subtitle {
    margin: 0;
}

.mat-card.p-0 .mat-card-title .mat-divider {
    margin-left: 0;
    margin-right: 0;
    border-top-color: rgba(0, 0, 0, 0.04);
}

.mat-card.p-0 .mat-card-image {
    margin: 0 0 1rem !important;
}

.mat-card.p-0 .mat-card-content {
    padding: 0 1.5rem 1.5rem;
}

.mdc-button,
.mat-mdc-raised-button {
    font-weight: 400 !important;
    // max-height: 2.5rem !important;
}

[mat-lg-button] {
    padding: 0 32px !important;
    font-size: 18px;
    line-height: 56px !important;
}

.mat-icon-button[mat-sm-button] {
    height: 24px;
    width: 24px;
    line-height: 24px;
}

.mat-icon-button[mat-xs-button] {
    height: 20px;
    width: 20px;
    line-height: 20px;
}

.mat-icon-button[mat-xs-button] .mat-icon {
    font-size: 16px;
    line-height: 20px !important;
    height: 20px;
    width: 20px;
}

.mat-chip[mat-sm-chip] {
    padding: 4px 6px 4px 6px !important;
    border-radius: 4px !important;
    font-size: 12px !important;
    line-height: 12px !important;
    max-height: 20px;
    box-sizing: border-box;
}

.mat-icon-button.img-button img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.compact-list .mat-list-item {
    line-height: 1.1;
    transition: all .3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.compact-list .mat-list-item:hover {
    background: rgba(0, 0, 0, .08);
    cursor: pointer;
}

.logo-group {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.full-width {
    width: 100% !important;
}

.auto-width {
    width: auto !important;
}

.auto-height {
    height: auto !important;
}

.full-height {
    height: 100%;
}

.mat-form-field-appearance-fill .mat-form-field-subscript-wrapper {
    top: calc(100% - 1.8666667em);
}

.dnd-item {
    cursor: grab;
}

.icon-circle {
    color: rgb(255, 255, 255);
    font-size: 1.2rem;
    text-align: center;
    line-height: 1.6rem;
    border-radius: 50%;
}

.mat-sidenav .mat-list-item:hover {
    background: rgba(0, 0, 0, .035) !important;
}

.mat-chip {
    position: relative;
    overflow: hidden;
}

.text-small {
    font-size: .813rem;
}

.text-small .mat-icon {
    font-size: 1rem;
    vertical-align: sub;
    margin: 0 2px;
}

.text-bigger {
    font-size: 1.3rem;
}


.mat-card-title {
    font-size: 1rem !important;
}

.icon-chip {
    font-size: 11px !important;
    padding: 4px 8px !important;
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.icon-chip .mat-icon {
    font-size: 16px;
    height: 16px;
    width: 16px;
    margin-right: 4px;
}

.mat-chip:not(.mat-basic-chip) {
    display: inline-block;
    padding: 8px 12px 8px 12px;
    border-radius: 24px;
    font-size: 13px;
    line-height: 16px;
}

.ql-container .ql-editor {
    //    min-height: 200px;
}

.chart {
    display: block;
    width: 100%;
}

.form-error-msg {
    margin-top: -1px;
}

.accordion-handle {
    cursor: pointer;
}

.app-accordion {
    max-height: 200px;
    transition: max-height .3s ease;
}

.app-accordion.open {
    max-height: 1000px;
}

.app-accordion .accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height .3s ease;
}

.app-accordion.open .accordion-content {
    max-height: 800px;
}

.app-accordion.open .hidden-on-open {
    display: none !important;
}

.app-accordion:not(.open) .show-on-open {
    display: none !important;
}

mat-list a[mat-list-item] .mat-list-item-content,
mat-list mat-list-item .mat-list-item-content,
mat-nav-list a[mat-list-item] .mat-list-item-content,
mat-nav-list mat-list-item .mat-list-item-content,
[mat-menu-item],
.mat-option,
body .mat-select-trigger {
    font-size: .8rem !important;
}

.mat-ripple {
    position: relative;
}

.fileupload-drop-zone {
    text-align: center;
    border: 1px dashed rgba(0, 0, 0, .15);
    padding: 48px;
    height: 120px;
}

.default-table {
    text-align: left;
}

.default-table > thead tr th {
    font-weight: 400;
    padding: .9rem 1.2rem;
    border-bottom: 1px solid rgba(0, 0, 0, .12);
}

.default-table tbody tr td {
    padding: .9rem 1.2rem;
}

.app-error {
    text-align: center;
    width: 320px;
    max-width: 320px;
    margin: 0 auto;
}

.app-error .error-icon {
    height: 120px;
    width: 120px;
    font-size: 120px;
    float: left;
}

.app-error .error-text {
    float: right;
    width: 200px;
    max-width: 200px;
}

.app-error .error-title {
    line-height: 6rem;
    font-size: 5rem;
    font-weight: 900;
    margin: 0;
}

.app-error .error-subtitle {
    font-size: 1.5rem;
    font-weight: 300;
}

.app-error .error-actions {
    width: 100%;
    overflow: hidden;
    min-height: 54px;
    margin-top: 100px;
}


/*---- Loader ----*/
.app-loader,
.view-loader {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
}

.view-loader {
    display: block;
    padding-top: 160px;
    background: rgba(255, 255, 255, .3);
    z-index: 9999;
}

.view-loader * {
    margin: auto;
}

.spinner {
    width: 40px;
    height: 40px;
    position: relative;
    margin: auto;
}

.double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
    0%, 100% {
        transform: scale(0.0)
    }
    50% {
        transform: scale(1.0)
    }
}

@keyframes sk-bounce {
    0%, 100% {
        transform: scale(0.0);
    }
    50% {
        transform: scale(1.0);
    }
}

/*---- Third party adjust -----*/

/*------ Map ------*/
.agm-info-window-content {
    color: rgba(0, 0, 0, .87);
}

.ps__rail-y {
    right: 0 !important;
    left: auto !important;
}

[dir=rtl] {
    .pr-1 {
        padding-left: 1rem;
        padding-right: 0 !important;
    }

    .mr-1 {
        margin-right: 0 !important;
        margin-left: 1rem;
    }

    .ps__rail-y {
        right: auto !important;
        left: 0 !important;
    }

    .ps__thumb-y {
        right: auto !important;
        left: 1px !important;
    }

    .ngx-datatable .datatable-footer .datatable-pager .pager {
        float: left;
    }

    .user-card .user-details .mat-icon {
        margin-right: 0;
        margin-left: .5rem;
    }
}

@media (max-width: 767px) {
    .mat-card-title .mat-divider {
        margin-left: 0;
        margin-right: 0;
    }
    .accordion-handle {
        flex-direction: column !important;
    }
    .app-error .error-icon {
        height: 100px;
        width: 100px;
        font-size: 100px;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    //-webkit-box-shadow: 0 0 0 30px map-get($mat-primary, lighter) inset !important;
    //-webkit-text-fill-color: $dark-primary-text !important;
}

.multiline-mat-option.mat-option {
    white-space: normal;
    line-height: normal;
}

// material tree dragging
//@import '@angular/material/theming';
.mat-tree-node {
    //background-color: $background;

    .mover {
        user-select: none;
        cursor: move;
    }

    &.cdk-drag-preview {
        @include mat.elevation(3);
    }

    &.cdk-drag-placeholder {
        opacity: 0;
    }
}

.cdk-drop-list-dragging .mat-tree-node:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-animating {
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1);
}

.mat-tab-body-wrapper {
    padding-top: 1em
}

.mat-mdc-tab-body-content {
    //height: auto !important;
    max-height: 80vh;
}

[mat-sort-header].cdk-keyboard-focused .mat-sort-header-container, [mat-sort-header].cdk-program-focused .mat-sort-header-container {
    border-bottom: none !important;
}

.mat-column-actions div button:last-of-type {
    margin-right: 0 !important;
}

.mt-2 {
    margin-top: 2rem !important;
}

.mb-2 {
    margin-bottom: 2rem !important;
}

.mt-25 {
    margin-top: 2.5rem !important;
}

.mb-25 {
    margin-bottom: 2.5rem !important;
}

.fit-content .mat-mdc-select-panel {
    min-width: fit-content;
}

.hidden {
    display: none !important;
}

.cursor-pointer {
    cursor: pointer;
}

.odd-row{
    background-color: rgba(0, 0, 0, 0.015);
}

.odd-row:hover{
    background-color: rgba(0, 0, 0, 0.15)
}

.absolute-hint{
    position: absolute;
    top: 1px;
}
