@use "@angular/material" as mat;
@import "../variables";

app-web-editor {

    height: 100%;

    .mat-drawer-content {
        height: auto;
        overflow: hidden;
    }
}

.block-borders {
    position: absolute;
    z-index: 999;
}

.block-borders.vertical {
    height: 100%;
    width: 10px;
}

.block-borders.horizontal {
    height: 10px;
    width: 100%;
}

.dragging-on .block-borders {
    background-color: green;
}

.block-borders.active {
    background-color: orange;
}

.grid-block > .block-actions {
    position: absolute;
    top: 10px;
    right: 10px;
    display: none;
    // color: $dark-secondary-text;
    color: mat.get-theme-color($theme, foreground, secondary-text);
    z-index: 998;

    button:not(:last-child) {
        margin-right: 0.5rem;
    }
}

.mat-mdc-raised-button.visible-on-hover {
    //padding: 3px;
    min-width: 30px !important;
    line-height: 20px;
}

.grid-block-remove {
    cursor: pointer !important;
}

.dragger {
    cursor: move !important;
}

.draggable:hover .block-actions {
    display: flex;
}

.draggable:hover .block-actions > .mat-icon {
    display: block;
}

.draggable:hover {
    border-color: mat.get-theme-color($theme, foreground, secondary-text);
}

.draggable {
    box-sizing: border-box;
    border: 1px solid transparent;
    height: 100%
}

.draggable .visible-on-hover {
    position: absolute;
    display: none;
}

.draggable:hover .visible-on-hover {
    display: block;
}

button.mat-button.add-block {
    width: 100%;
    color: darken(mat.get-theme-color($theme, foreground, secondary-text), 50%);
}

/*.ck.ck-content.ck-editor__editable.ck-editor__editable_inline {
    --ck-spacing-standard: 0 !important;
}

.ck.ck-content.ck-editor__editable_inline {
    overflow: unset;
}
*/
.ck.ck-content.ck-editor__editable.ck-editor__editable_inline.ck-focused {
    border-color: map-get($mat-primary, lighter);
}

/*.ck-content hr {
    background-color: mat.get-theme-color($theme, foreground, text) !important;
    height: 1px !important;
}
*/

.ck.ck-editor__editable_inline {
    background-color: var(--mdc-filled-text-field-container-color);
    position: relative;

    &:hover, &:focus {
        background-color: lighten(map-get($mat-primary, lighter), 20);
    }
}

.ck-powered-by-balloon {
    display: none !important;
}


