app-grid, app-grid-editor {

    flex: 1;

    .mat-grid-tile .mat-figure {
        align-items: flex-start
    }

    .page-grid {
        display: grid;
        //var in grid-component
        grid-template-columns: var(--item-min-width);
        grid-gap: 0;
    }

    .page-grid > div {
        position: relative
    }

    div[app-text-block], div[app-text-image-block], div[app-company-address-block], div[app-text-block-editor], div[app-text-image-block-editor] {
        padding: 1em
    }

    &:not(.first-grid) div[app-image-block], &:not(.first-grid) div[app-image-block-editor] {
        padding: 0 1em;
    }

    div[app-text-image-block], div[app-text-image-block-editor] {

        .image-on-top {

            img {
                margin: 0 0 1rem 0;
                @media (max-width: 767px) {
                    width: 100% !important;
                }
            }

            @media (max-width: 767px) {
                width: 100% !important;
            }
        }

        .image-on-bottom img {
            margin-top: 1rem;
            @media (min-width: 768px) {
                width: 100% !important;
            }
        }

        .image-on-top.image-on-center img {
            float: none !important;
            @media (min-width: 768px) {
                width: 100% !important;
            }
        }

        .image-on-left img {
            float: left;
        }

        .image-on-right img {
            float: right
        }

        .image-on-left:not(.image-on-bottom) img {
            margin-right: 1rem;
        }

        .image-on-right:not(.image-on-bottom) img {
            margin-left: 1rem;
        }
    }

    .image-loader {
        z-index: 2;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%)
    }

    @media (min-width: 768px) {
        width: 90%;
    }

    @media all and (max-width: 767px) {
        width: 100%;

        .page-grid {
            display: flex;
            flex-flow: column;

            /*div[app-text-image-block] {
                display: flex;
                align-items: center;
                flex-direction: row;
                flex-flow: column;
                justify-content: space-around;

                .iw {
                    flex-basis: 100%;
                }

                .iw.image-on-left {
                    flex-basis: 30%;

                    img {
                        max-width: 100%;
                        height: auto;
                        margin: 0 !important;
                    }
                }

                & + .text {
                    flex-basis: 70%;
                    padding: 0 1rem;;
                }
            }*/
        }
    }
}
