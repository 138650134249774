@use '@angular/material' as mat;

// Light Theme Text
$dark-text: #232323;
$dark-primary-text: rgba($dark-text, 0.87);

// Dark Theme text
$light-text: #efefef;
$light-primary-text: $light-text;

// Theme Config
$mat-primary: (
    main: #232323,
    lighter: #bdbdbd,
    darker: #151515,
    200: #232323, // For slide toggle,
    contrast : (
        main: $light-primary-text,
        lighter: $dark-primary-text,
        darker: $light-primary-text,
    )
);

$mat-accent: (
    main: #2EC610,
    lighter: #F2FDED,
    darker: #7C9971,
    200: #2EC610, // For slide toggle,
    contrast : (
        main: $dark-primary-text,
        lighter: $dark-primary-text,
        darker: $dark-primary-text,
    )
);

$mat-warn: (
    main: #A33C30,
    lighter: lighten(#A33C30, 40),
    darker: darken(#A33C30, 20),
    200: darken(#A33C30, 20), // For slide toggle,
    contrast : (
        main: $light-primary-text,
        lighter: $dark-primary-text,
        darker: $light-primary-text,
    )
);


$mat-error: (
    main: #7d2d27,
    lighter: #d8c0be,
    darker: #601c17
);

$mat-info: (
    main: #ECCF4F,
    lighter: lighten(#ECCF4F, 40),
    darker: darken(#ECCF4F, 20)
);

$mat-success: (
    main: #2EC610,
    lighter: #F2FDED,
    darker: #7C9971
);

// Compute font config
$fontConfig: mat.define-typography-config(
    $headline-1: mat.define-typography-level(112px, 112px, 300, 'Open Sans', -0.0134em),
    $headline-2: mat.define-typography-level(56px, 56px, 400, 'Open Sans', -0.0089em),
    $headline-3: mat.define-typography-level(45px, 48px, 400, 'Open Sans', 0.0000em),
    $headline-4: mat.define-typography-level(34px, 40px, 400, 'Open Sans', 0.0074em),
    $headline-5: mat.define-typography-level(32px, 40px, 500, 'Open Sans', 0.0000em),
    $headline-6: mat.define-typography-level(20px, 32px, 500, 'Open Sans', 0.0075em),
    $subtitle-1: mat.define-typography-level(16px, 20px, 400, 'Open Sans', 0.0094em),
    $subtitle-2: mat.define-typography-level(15px, 24px, 500, 'Open Sans', 0.0067em),
    $body-1: mat.define-typography-level(14px, 24px, 500, 'Open Sans', 0.0179em),
    $body-2: mat.define-typography-level(14px, 20px, 400, 'Open Sans', 0.0179em),
    $caption: mat.define-typography-level(12px, 20px, 400, 'Open Sans', 0.0333em)
);

$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

$my-primary: $theme-primary;
$my-accent: $theme-accent;

$theme: mat.define-light-theme((
    color: (
        primary: $theme-primary,
        accent: $theme-accent,
        warn: $theme-warn,
    ),
    typography: $fontConfig,
    density: 0
));

$altTheme: mat.define-dark-theme((
    color: (
        primary: $theme-primary,
        accent: $theme-accent,
        warn: $theme-warn),
));

/*$adminTheme: mat.define-dark-theme((
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn),
));*/
