@use '@angular/material' as mat;
@import '../variables';

::-webkit-scrollbar {
    width: 3px;
}

::-webkit-scrollbar-track {
    border-radius: 0;
    border-right: 1px solid transparentize($dark-text, 0.6);
}

::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: transparentize($dark-text, 0.2);

    //-webkit-box-shadow: inset 0 0 5px $dark-text;
}

::-webkit-scrollbar:hover{
    width: 20px;
}
