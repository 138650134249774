@use '@angular/material' as mat;
//@import '@angular/material/theming';
@import '../../../_variables';

.front-theme footer {

    .mdc-subheader {
        @include mat.typography-level($fontConfig, 'subtitle-1');
        color: $light-primary-text; //mat-color($mat-accent, main);
        text-transform: uppercase;
    }

    .mdc-list a.mdc-list-item .mdc-list-item__primary-text {
        color: $light-primary-text !important;
    }

    .mdc-list .mat-list-item .mdc-list-item-content {
        padding-left: 1.5rem !important;
    }

    .mdc-list .mat-list-item:before {
        font-size: 24px;
        font-family: "Material Icons", serif;
        content: 'arrow_right';
        position: absolute;
        top: 11px;
    }

    .mdc-list .mdc-list-item:hover:before {
        color: map-get($mat-accent, main);
    }

}

