app-root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex !important;
    flex-direction: row
}

.flex-col {
    display: flex !important;
    flex-direction: column
}

.grid {
    display: grid
}

.flex-wrap {
    flex-wrap: wrap
}

.flex-col-xs {
    @media screen and (max-width: 599px) {
        flex-direction: column;
    }
}

.gap-1 {
    gap: 1em
}

.gap-percent-1 {
    gap: 1%
}

.gap-2 {
    gap: 2em
}

.gap-25 {
    gap: 2.5em
}

.space-between {
    justify-content: space-between
}

.justify-center {
    justify-content: center !important
}

.justify-end {
    justify-content: flex-end !important
}

.justify-start {
    justify-content: flex-start !important
}

.items-center {
    align-items: center
}

.items-start {
    align-items: flex-start;
}

.items-end {
    align-items: flex-end;
}

.items-stretch {
    align-items: stretch;
}

.self-align-start {
    align-self: flex-start;
}

.self-align-end {
    align-self: flex-end;
}

.flex-1 {
    flex: 1
}

.flex-grow {
    flex-grow: 1
}

.flex-shrink {
    flex-shrink: 1
}

.flex-200px {
    width: 200px
}

.flex-150px {
    flex-basis: 150px
}

.flex-140px {
    flex-basis: 140px
}

.flex-100px {
    flex-basis: 100px
}

.flex-50px {
    flex-basis: 50px
}

.flex-40px {
    flex-basis: 40px
}

.flex-0 {
    flex-basis: 0;
}

.flex-100 {
    flex-basis: 100%
}

.flex-90 {
    flex-basis: 90%
}

.flex-85 {
    flex-basis: 85%
}

.flex-80 {
    flex-basis: 80%
}

.flex-78 {
    flex-basis: 78%
}

.flex-73 {
    flex-basis: 73%
}

.flex-70 {
    flex-basis: 70%
}

.flex-69 {
    flex-basis: 69%
}

.flex-68 {
    flex-basis: 68%
}

.flex-66 {
    flex-basis: 66%
}

.flex-60 {
    flex-basis: 60%
}

.flex-59 {
    flex-basis: 59%
}

.flex-58 {
    flex-basis: 58%
}

.flex-50 {
    flex-basis: 50%
}

.flex-49 {
    flex-basis: 49%
}

.flex-48 {
    flex-basis: 48%
}

.flex-45 {
    flex-basis: 45%
}

.flex-42 {
    flex-basis: 42%
}


.flex-40 {
    flex-basis: 40%
}

.flex-38 {
    flex-basis: 38%
}

.flex-35 {
    flex-basis: 35%
}

.flex-33 {
    flex-basis: 33%
}

.flex-32 {
    flex-basis: 32%
}


.flex-30 {
    flex-basis: 30%
}

.flex-28 {
    flex-basis: 28%
}

.flex-25 {
    flex-basis: 25%
}

.flex-20 {
    flex-basis: 20%
}

.flex-19 {
    flex-basis: 19%
}

.flex-18 {
    flex-basis: 18%
}

.flex-17 {
    flex-basis: 18%
}

.flex-15 {
    flex-basis: 15%
}

.flex-14 {
    flex-basis: 14%
}

.flex-13 {
    flex-basis: 13%
}

.flex-10 {
    flex-basis: 10%
}

.flex-8 {
    flex-basis: 8%
}

.flex-7 {
    flex-basis: 7%
}

.flex-6 {
    flex-basis: 6%
}


.flex-5 {
    flex-basis: 5%
}

.flex-4 {
    flex-basis: 4%;
}

.flex-2 {
    flex-basis: 2%;
}

.flex-auto {
    flex-basis: auto
}

.flex-offset-1 {
    offset: 1%
}

.flex-offset-2 {
    offset: 2%;
}

.flex-offset-10 {
    offset: 10%
}

@media screen and (max-width: 599px) {
    .flex-xs-100, .flex-lt-sm-100 {
        flex-basis: 100%
    }
    .flex-xs-50 {
        flex-basis: 50%
    }

    .hide-xs {
        display: none
    }

    .flex-lt-sm-col, flex-xs-col {
        flex-direction: column
    }

    .flex-lt-sm-items-center {
        align-items: center
    }

    .flex-lt-sm-justify-start {
        justify-content: flex-start
    }
}

@media screen and (min-width: 600px) {
    .hide-gt-xs {
        display: none
    }
    .flex-gt-xs-auto {
        flex-basis: auto
    }
    .flex-gt-xs-33 {
        flex-basis: 33%
    }
    .flex-gt-xs-50 {
        flex-basis: 50%
    }
    .flex-gt-xs-70 {
        flex-basis: 70%
    }
    .flex-gt-xs-grow {
        flex-grow: 1
    }
    .flex-gt-xs-grow {
        flex-wrap: wrap
    }
}

@media screen and (min-width: 960px) {
    .hide-gt-sm {
        display: none !important;
    }
    .flex-gt-sm-row {
        flex-direction: row !important;
    }
    .flex-gt-sm-15 {
        flex-basis: 15%
    }
    .flex-gt-sm-20 {
        flex-basis: 20%
    }
    .flex-gt-sm-25 {
        flex-basis: 25%
    }
    .flex-gt-sm-30 {
        flex-basis: 30%
    }
    .flex-gt-sm-33-33 {
        flex-basis: 33.33%
    }
    .flex-gt-sm-40 {
        flex-basis: 40%
    }
    .flex-gt-sm-49 {
        flex-basis: 49%
    }
    .flex-gt-sm-50 {
        flex-basis: 50%
    }
    .flex-gt-sm-60 {
        flex-basis: 60%
    }
}

@media screen and (max-width: 959px) {
    .hide-lt-md, hide-sm {
        display: none !important;
    }
}

@media screen and (min-width: 1198px) {
    .flex-gt-md-row {
        flex-direction: row !important;
    }
    .flex-gt-md-wrap {
        flex-wrap: wrap !important;
    }
    .flex-gt-md-100 {
        flex-basis: 100% !important;
    }
    .flex-gt-md-49 {
        flex-basis: 49% !important;
    }
    .flex-gt-md-25 {
        flex-basis: 25% !important;
    }
    .flex-gt-md-10 {
        flex-basis: 10% !important;
    }
}

@media screen and (max-width: 1200px) {
    .flex-lt-lg-auto {
        flex-basis: auto
    }
}

.hide {
    display: none;
}

